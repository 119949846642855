/* eslint-disable react/jsx-pascal-case */
import "./OrderCreate_05_Summary.scss"

import Map_ from "../../../../../../atoms/Map/Map"
import { PageStage, PageStep } from "../../PageState"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"
import {
  setActiveAddresses,
  setActiveComplete,
  setActiveLogin,
  setActivePackages,
  setActivePayment,
  setActivePhotos,
  setActivePickupAndDeliveryDetails,
  setActiveSummaryAndAgreement,
  setStage,
  setStep
} from "../../../../../../redux/slices/stepSlice"
import { buildMarkers, buildPolylines } from "../../../../../../atoms/Map/utils"
import { useMediaQuery } from 'react-responsive';
import { useUser } from "../../../../../layout/Layout/Layout";
import { orderUpdate_confirmAndHold } from "../../../../../../api/order/order_api";
import { OrderUpdate_ConfirmAndAgreementRequest } from "../../../../../../models/order/Order";
import { Breakpoint } from "../../../../../../config/breakpoints";
import { OrderPhoto } from "../../../../../../models/order/OrderFiles"
import { getBackendUrl } from "../../../../../../config/env"
import { userOrderFileDownload } from "../../../../../../api/order/user_order_api"

export default function OrderCreate_05_Summary() {
  const dispatch = useDispatch<AppDispatch>()
  const orderId = useSelector((state: RootState) => state.order.order_id)
  const addressFrom = useSelector((state: RootState) => state.order.pickup_from)
  const addressTo = useSelector((state: RootState) => state.order.delivery_to)
  const packages = useSelector((state: RootState) => state.order.packages)
  const pickupAtInput = useSelector((state: RootState) => state.order.pickup_at_input)
  const pickupPhoneNumber = useSelector((state: RootState) => state.order.pickup_phone_number)
  const deliveryAtInput = useSelector((state: RootState) => state.order.delivery_at_input)
  const deliveryPhoneNumber = useSelector((state: RootState) => state.order.delivery_phone_number)
  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const rate = useSelector((state: RootState) => state.order.user_rate)
  const isActiveSummaryAndAgreement = useSelector((state: RootState) => state.step.isActiveSummaryAndAgreement)
  const isActiveComplete = useSelector((state: RootState) => state.step.isActiveComplete)
  const order = useSelector((state: RootState) => state.order)
  const stepRef = useRef<HTMLDivElement>(null)
  const uploadedPhotos = useSelector((state: RootState) => state.order.files?.photos_on_creation)
  const [photosSrc, setPhotosSrc] = useState<string[] | undefined>([]) 
  const today = new Date();
  const formattedToday = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const [confirmationChecked, setConfirmationChecked] = useState({
    authorization: false,
    terms: false,
  })

  const getPhoto = async (photo: any) => {
    const photoBlob: Blob | undefined = await userOrderFileDownload(orderId, photo.file_id)
    if (!photoBlob) {
      console.error("Photo URL is undefined")
      return
    }
    return URL.createObjectURL(photoBlob)
  }

  const buildPhotos = () => {
    if (photosSrc?.length === 0) {
      return <p className="user_order_create__summary__detail_data">No photos uploaded</p>
    }
    return photosSrc?.map((src, index) => {
      return (
        <img
          src={src}
          alt={`Uploaded Preview ${index}`}
          className="user_order_create__summary__detail_photo"
        />
      )
    })
  }

  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })

  const buildPackages = () => {
    let index = 0
    return packages.map((packageItem) => {
      index += 1
      return <p className="user_order_create__summary__detail_data">
        {index}. {packageItem.length_in}x{packageItem.width_in}x{packageItem.height_in} in, {packageItem.weight_lb}
      </p>
    })
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.checked

    setConfirmationChecked((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEditClick = () => {
    dispatch(setStage(PageStage.Edit))
    dispatch(setStep(PageStep.Addresses))

    dispatch(setActiveAddresses(true))
    dispatch(setActivePackages(true))
    dispatch(setActivePickupAndDeliveryDetails(true))
    dispatch(setActivePhotos(true))

    dispatch(setActiveSummaryAndAgreement(false))
    dispatch(setActiveLogin(false))
    dispatch(setActivePayment(false))
    dispatch(setActiveComplete(false))
  }

  const {user} = useUser()

  const handleProceedButton = () => {
    if (!user) {
      dispatch(setStep(PageStep.Login))
      dispatch(setActiveSummaryAndAgreement(true))
      dispatch(setActiveLogin(true))
      dispatch(setActivePayment(false))
      dispatch(setActiveComplete(false))
    } else {
      dispatch(setStep(PageStep.Payment))
      dispatch(setActiveSummaryAndAgreement(true))
      dispatch(setActiveLogin(false))
      dispatch(setActivePayment(true))
      dispatch(setActiveComplete(false))
    }
  }

  const createSectionTitle = () => {
    return (
      isActiveComplete
      ? <h2 className="user_order_create__summary__title user_order_create__summary__title-complete">
          Order #${orderId}<br />
          <span className="user_order_create__summary__date">Order date: {formattedToday}</span>
        </h2>
      : <h2 className="user_order_create__summary__title">Review your shipping order</h2>
    )
  }

  const createRateElementClass = (element: string) => {
    return ( currentStep === PageStep.SummaryAndAgreement )
      ? `user_order_create__summary__agreements__${element}`
      : `user_order_create__summary__agreements__${element} user_order_create__summary__agreements__${element}-confirmed`
  }

  useEffect(() => {
    if (!uploadedPhotos) {
      setPhotosSrc([])
      return
    }
    const fetchPhotos = async () => {
      const photoUrls = await Promise.all(uploadedPhotos.map(getPhoto))
      setPhotosSrc(photoUrls.filter((url): url is string => !!url))
    };

    fetchPhotos()

  }, [uploadedPhotos])

  useEffect(() => {
    if (currentStep === PageStep.SummaryAndAgreement && stepRef.current) {
      const offsetTop = stepRef.current?.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 90,
        behavior: "smooth",
      })
    }
  }, [currentStep])

  return (isActiveSummaryAndAgreement &&
      <section className="user_order_create__summary__section" ref={stepRef}>
        {(!isDesktopScreen  && (currentStep !== PageStep.Complete)) &&
          <button
            className="user_order_create__summary__edit-link"
            onClick={handleEditClick}
          >
            Back to Edit Order
          </button>
        }
        <div className="user_order_create__summary__wrapper">
            <div className="user_order_create__summary__heading">
              {createSectionTitle()}
              {isDesktopScreen && (
                (currentStep !== PageStep.Complete)
                  ? <button
                      className="user_order_create__summary__edit-border"
                      onClick={handleEditClick}
                    >
                      Edit order
                    </button>
                  : <p className="user_order_create__summary__status">Pending</p>
                )
              }
            </div>
            <div className="user_order_create__summary__summary">
                <div className="user_order_create__summary__map_wrapper">
                    <Map_
                        polylines={buildPolylines(order)}
                        markers={buildMarkers([order])}
                        className="user_order_create__summary_and_agreement_map"
                        width="100%"
                    />
                </div>
                <div className="user_order_create__summary__details">
                    <div className="user_order_create__summary_and_agreement_detail">
                        <h5 className="user_order_create__summary__detail_title">
                            Pickup
                        </h5>
                        <p className="user_order_create__summary__detail_data">
                            From: {addressFrom?.full_address}
                        </p>
                        <p className="user_order_create__summary__detail_data">
                            At: {
                          (pickupAtInput === "" || !pickupAtInput)
                            ? "As Soon As Possible"
                            : pickupAtInput
                        }
                        </p>
                        <p className="user_order_create__summary__detail_data">
                            Contact: {
                          (pickupPhoneNumber === ""  || !pickupPhoneNumber)
                            ? "Contactless"
                            : pickupPhoneNumber
                        }
                        </p>
                    </div>
                    <div className="user_order_create__summary_and_agreement__detail">
                        <h5 className="user_order_create__summary__detail_title">
                            Delivery
                        </h5>
                        <p className="user_order_create__summary__detail_data">
                            To: {addressTo?.full_address}
                        </p>
                        <p className="user_order_create__summary__detail_data">
                            At: {
                          (deliveryAtInput === ""  || !deliveryAtInput)
                            ? "As Soon As Possible"
                            : deliveryAtInput
                        }
                        </p>
                        <p className="user_order_create__summary__detail_data">
                            Contact: {
                          (deliveryPhoneNumber === ""  || !deliveryPhoneNumber)
                            ? "Contactless"
                            : deliveryPhoneNumber
                        }
                        </p>
                    </div>
                    <div className="user_order_create___summary_and_agreement_detail">
                        <h5 className="user_order_create__summary__detail_title">
                            Package(s)
                        </h5>
                      {buildPackages()}
                    </div>
                    <div className="user_order_create___summary_and_agreement_detail">
                        <h5 className="user_order_create__summary__detail_title">
                            Photos
                        </h5>
                        <p className="user_order_create__summary__detail_photos">
                          {buildPhotos()}
                        </p>
                    </div>
                </div>
            </div>
            {(currentStep === PageStep.SummaryAndAgreement) &&
              <div className="user_order_create__summary__agreements">
                <input
                  className="user_order_create__summary__agreements_confirm-input"
                  type="checkbox"
                  name="authorization"
                  id="authorization"
                  checked={confirmationChecked.authorization}
                  onChange={(event) => handleCheckboxChange(event)}
                />
                <label
                  className="user_order_create__summary__agreements_confirm-label"
                  htmlFor="authorization"
                >
                  By clicking 'Submit,' you confirm the rate mentioned above and authorize a pre-authorization on your saved payment method. We won't charge your card until your package is delivered.
                </label>
                <input
                  className="user_order_create__summary__agreements_terms-input"
                  type="checkbox"
                  name="terms"
                  id="terms"
                  checked={confirmationChecked.terms}
                  onChange={(event) => handleCheckboxChange(event)}
                />
                <label
                  className="user_order_create__summary__agreements_terms-label"
                  htmlFor="terms"
                >
                  I have read and agreed with the Terms and Conditions
                </label>
              </div>
            }
            <div className="user_order_create__summary__agreements__rate_section">
              <div className={createRateElementClass("rate_wrapper")}>
                  <div className={createRateElementClass("rate_title")}>
                      Your Rate:
                  </div>
                  <div className={createRateElementClass("rate_amount")}>{rate} $</div>
              </div>
              {(currentStep === PageStep.SummaryAndAgreement) &&
                <button
                      onClick={handleProceedButton}
                      className="user_order_create__summary__agreements__rate_button"
                      disabled = {!(confirmationChecked.authorization && confirmationChecked.terms)}
                  >
                      Submit Order
                </button>
              }
          </div>
        </div>
      </section>
  )
}
