import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../models/user/User"
import { UserRole } from "../../models/user/UserRole"

const initialState: User = {
  user_id: "",
	created_at: "",
	updated_at: undefined,
	email: "",
  role: UserRole.USER,
  selected_role: undefined,
  name: undefined,
  phone_number: undefined,
  driver_status: undefined,
  manager_status: undefined,
  stripe_data: undefined,
  timezone: undefined,
  signed_current_agreement: false,
}

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		updateStoredUser: (state, action: PayloadAction<User>) => {
			Object.assign(state, action.payload) 
		},
		setUserId: (state, action: PayloadAction<string>) => {
			state.user_id = action.payload
		},
		setSignedCurrentAgreement: (state, action: PayloadAction<boolean>) => {
			state.signed_current_agreement = action.payload
		},
		removeStoredUser: (state) => {
			Object.assign(state, initialState)
		}
	}
})

export const {updateStoredUser} = userSlice.actions
export const {setUserId} = userSlice.actions
export const {setSignedCurrentAgreement} = userSlice.actions
export const {removeStoredUser} = userSlice.actions

export default userSlice.reducer
