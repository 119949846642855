import "./UserOrdersHistory.scss"
import { useDebugState } from 'use-named-state'
import { useUserOrders } from '../../../../api/order/user_order_api'
import OrderTablesStatusFilter from '../../OrderTablesStatusFilter/OrderTablesStatusFilter'
import { fromUserOrderStatus, toUiOrderStatus } from '../../../../utils/order_status_utils'
import { OrderStatus } from '../../../../models/order/OrderStatus'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { DATETIME_WIDTH, DRIVER_PHONE_NUMBER_WIDTH, ID_WIDTH, ORDER_STATUS_WIDTH, RATE_WIDTH, SHORT_ADDRESS_WIDTH, USER_NAME_WIDTH } from '../../../../config/table_witdth_config'
import { USER__ORDER_CREATE__PATH } from '../../../../config/page_paths'
import { useNavigate } from "react-router-dom";

export default function UserOrdersHistory() {
  const page = 0
  const pageSize = 10
  const [orderStatusFilter, setOrderStatusFilter] = useDebugState<OrderStatus | undefined>('orderStatusFilter', undefined)
  const { data: ordersPage } = useUserOrders(page, pageSize, fromUserOrderStatus(orderStatusFilter))
  const navigate = useNavigate()

  const handleVisitClick = () => {
    navigate(USER__ORDER_CREATE__PATH)
  }

  return (
    <section className="user_orders__section">
      <div className="user_orders__no_history">
        <img className="user_orders__no_history_image" src="/images/dashboard/image-no-history.png" width={300} height={300} alt="No orders history"/>
        <h2 className="user_orders__no_history_title">Your order history is empty</h2>
        <p className="user_orders__no_history_hint">No previous orders found. Once you place an order, it will appear here.</p>
        <button onClick={handleVisitClick} className="user_orders__no_history_button">Rate & Ship</button>
      </div>
    </section>
)}
