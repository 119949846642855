import "./style.css"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js"
import StripeSetupIntentForm from "../StripeSetupIntentForm/StripeSetupIntentForm"
import { getStripePublicKey } from "../../../../../config/env"
import { Order } from "../../../../../models/order/Order"


const options = { mode: "setup", currency: "usd", appearance: {} } as StripeElementsOptions

interface Props {
  order: Order
  mutateOrder: () => void
}

export default function UserStripeSetupIntent({ order, mutateOrder }: Props) {
  return <>
    <Elements stripe={loadStripe(getStripePublicKey() || "")} options={options}>
      <StripeSetupIntentForm order={order} mutateOrder={mutateOrder}/>
    </Elements>
  </>
}
