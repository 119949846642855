import { motion } from 'framer-motion';
import './DriverDashboardPage.scss'
import { useState } from 'react';
import DriverDashboard from '../../../../components/dashboard/DriverDashboard/DriverDashboard';
import DriverRouteCreate from "../../../../components/route/driver/DriverRouteCreate/DriverRouteCreate";

enum DriverDashboardTab {
  availableOrders,  //0
  routes, //1
  stripe, //2
  agreements, //3
}

const DriverDashboardTabs = [
  {
    id: 0,
    name: "available",
    label: "Available Orders",
  },
  {
    id: 1,
    name: "routes",
    label: "My Routes",
  },
  {
    id: 2,
    name: "stripe",
    label: "Stripe",
  },
  {
    id: 3,
    name: "agreements",
    label: "Agreements",
  },
]

export default function DriverDashboardPage() {
  const [currentTab, setCurrentTab] = useState(DriverDashboardTab.availableOrders)

  const handleTabClick = (tab: number) => {
    if (currentTab !== tab) setCurrentTab(tab) 
  }

  const buildDashboardTabs = () => {
    return DriverDashboardTabs.map((tab) => {
      return (
        <motion.button 
          key={tab.id}
          className={
            `driver_dashboard__tab 
            ${currentTab === tab.id 
              ? "driver_dashboard__tab-active" 
              : ""
            }`
          }
          onClick={() => handleTabClick(tab.id)}
          layoutId="active-tab"
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
        > 
          <span className={
            `driver_dashboard__tab_${tab.name}
            ${currentTab === tab.id 
                ? `driver_dashboard__tab_${tab.name}-active`
                : ""
              }`
            }
          />
          {tab.label}
        </motion.button>
      )
    })
  }

  const buildDashboardContent = () => {
    switch (currentTab) {
      case DriverDashboardTab.availableOrders:
        return <DriverDashboard />
      case DriverDashboardTab.routes:
        return <DriverRouteCreate />
      case DriverDashboardTab.stripe:
        return ""
      case DriverDashboardTab.agreements:
        return ""
    }
  }

  return (
    <section className="driver_dashboard__page">
      <div className="driver_dashboard__tabs">
        {buildDashboardTabs()}
      </div>
      <div className="driver_dashboard__content-wrapper">
        <motion.div
          key={currentTab}
          className="driver_dashboard__content"
          initial={{ opacity: 0}}
          animate={{ opacity: 1}}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {buildDashboardContent()}
        </motion.div>
      </div>
    </section>
  )
}
