import "./UserOrderCreatePage.scss"
import { useParams } from "react-router-dom"
import OrderCreate from "../../../../components/order/user/UserOrderCreate/OrderCreate/OrderCreate"
import { useEffect, useState } from "react"
import { Order } from "../../../../models/order/Order"
import { orderGet } from "../../../../api/order/order_api"

export default function UserOrderCreatePage() {
  const { orderId } = useParams()
  const [order, setOrder] = useState<Order | undefined>()

  useEffect(() => {
    if (orderId) {
      console.log("UserOrderCreatePage.useEffect[orderId].mutateOrder")
      mutateOrder()
    }
  }, [orderId])

  function mutateOrder(): void {
    if (orderId || (order && order?.order_id)) {
      orderGet(orderId || (order && order?.order_id)!!)
        .then((order: Order) => {
          console.log("UserOrderCreatePage.useEffect[orderId].mutateOrder.orderGet.setOrder")
          setOrder(order)
        })
    }
  }

  return <div className="user_order_create_page">
    <OrderCreate order={order} setOrder={setOrder} mutateOrder={mutateOrder}/>
  </div>
}
