import { MarkerType } from './MarkerType'

export interface Marker {
  markerId: string
  entityId: string
  lat: number
  lng: number
  label: string
  color: string
  url: string
  type: MarkerType
  icon: google.maps.Symbol | google.maps.Icon
  payload: any
}

export const PICKUP_FROM_ICON = {
  path: 'M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z M 45 48.705 c -8.035 0 -14.548 -6.513 -14.548 -14.548 c 0 -8.035 6.513 -14.548 14.548 -14.548 s 14.548 6.513 14.548 14.548 C 59.548 42.192 53.035 48.705 45 48.705 z',
  fillColor: 'green',
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0,
  scale: .4,
  anchor: null,
} as google.maps.Symbol

export const DELIVERY_TO_ICON = {
  url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(52)}`,
  scaledSize: new window.google.maps.Size(52, 52),
  anchor: new window.google.maps.Point(52 / 2, 52 / 2),
} as google.maps.Icon

export const DELIVERY_SVG = `
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="26" cy="26" r="26" fill="#121212"/>
      <g filter="url(#filter0_d_1282_219543)">
        <circle cx="25.9998" cy="26.0013" r="10.8333" fill="#121212"/>
        <circle cx="25.9998" cy="26.0013" r="12.3333" stroke="white" stroke-width="3"/>
      </g>
      <defs>
        <filter id="filter0_d_1282_219543" x="3.86314" y="5.2485" width="44.2732" height="44.2747" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.38389"/>
          <feGaussianBlur stdDeviation="4.15168"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1282_219543"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1282_219543" result="shape"/>
        </filter>
      </defs>
    </svg>
  `

export const PICKUP_SVG =
  `<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="48" cy="48" r="47" stroke="white" stroke-width="2"/>
<circle cx="48.0016" cy="48.0016" r="45.4" fill="black" fill-opacity="0.15" stroke="#121212" stroke-width="2"/>
<g filter="url(#filter0_d_1282_219545)">
<path d="M47.3339 49.3629V69.2969L31.1363 59.5758L31.1004 39.1085L47.3339 49.3629Z" stroke="#121212" stroke-width="1.38389"/>
<path d="M64.9045 59.5762L48.7076 69.2969V49.3624L64.9045 39.1086V59.5762Z" stroke="#121212" stroke-width="1.38389"/>
<path d="M64.2766 37.8728L48.0209 48.1638L31.6889 37.8591L47.6003 28.0195L64.2766 37.8728Z" stroke="#121212" stroke-width="1.38389"/>
<path d="M47.4618 49.2832V69.0475L31.3878 59.4006L31.3522 39.1071L47.4618 49.2832Z" fill="#CD9C67" stroke="#121212" stroke-width="1.10712"/>
<path d="M64.6426 59.4009L48.5692 69.0475V49.2828L64.6426 39.1072V59.4009Z" fill="#AE7F52" stroke="#121212" stroke-width="1.10712"/>
<path d="M64.1383 38.1186L48.0179 48.324L31.8261 38.1078L47.6053 28.3499L64.1383 38.1186Z" fill="#EAB37A" stroke="#121212" stroke-width="1.10712"/>
<path d="M39.2031 43.2047L55.2031 32.8047" stroke="#121212" stroke-width="1.79906"/>
<path d="M44.289 60.0269C42.9652 59.2276 41.6413 58.4324 40.3133 57.6331C40.0814 57.4928 39.8664 57.8584 40.1025 57.9987C41.4263 58.7981 42.7502 59.5932 44.0782 60.3926C44.3101 60.5329 44.5251 60.1672 44.289 60.0269Z" fill="#121212" stroke="#121212" stroke-width="0.691947"/>
<path d="M44.289 61.6285C42.9652 60.8291 41.6413 60.034 40.3133 59.2346C40.0814 59.0943 39.8664 59.46 40.1025 59.6003C41.4263 60.3997 42.7502 61.1948 44.0782 61.9942C44.3101 62.1345 44.5251 61.7688 44.289 61.6285Z" fill="#121212" stroke="#121212" stroke-width="0.691947"/>
<path d="M44.289 63.2261C42.9652 62.4268 41.6413 61.6317 40.3133 60.8323C40.0814 60.692 39.8664 61.0576 40.1025 61.198C41.4263 61.9973 42.7502 62.7924 44.0782 63.5918C44.3101 63.7321 44.5251 63.3665 44.289 63.2261Z" fill="#121212" stroke="#121212" stroke-width="0.691947"/>
<path d="M34.1531 58.1064L34.4589 57.6476L34.1531 58.1064L36.2763 59.5215C36.7361 59.828 37.352 59.4983 37.352 58.9457V56.5875C37.352 56.3633 37.2434 56.153 37.0606 56.0232L34.9374 54.5159C34.4792 54.1907 33.8449 54.5183 33.8449 55.0802V57.5306C33.8449 57.762 33.9605 57.9781 34.1531 58.1064ZM36.8903 58.6003L36.7984 58.7381L36.8903 58.6003L36.89 58.6001C36.8901 58.6001 36.8902 58.6002 36.8903 58.6003Z" fill="white" stroke="#121212" stroke-width="1.10712"/>
</g>
<defs>
<filter id="filter0_d_1282_219545" x="24.8472" y="25.6016" width="46.2909" height="55.9891" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="5.53558"/>
<feGaussianBlur stdDeviation="2.76779"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1282_219545"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1282_219545" result="shape"/>
</filter>
</defs>
</svg>
`

export const buildIcon = (svg: string, size = 52): google.maps.Icon => {
  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
    scaledSize: new window.google.maps.Size(size, size),
    anchor: new window.google.maps.Point(size / 2, size / 2),
  }
}

export const DRIVER_ICO = {
  path: 'M37.409,18.905l-4.946-7.924c-0.548-0.878-1.51-1.411-2.545-1.411H3c-1.657,0-3,1.343-3,3v16.86c0,1.657,1.343,3,3,3h0.787 c0.758,1.618,2.391,2.75,4.293,2.75s3.534-1.132,4.292-2.75h20.007c0.758,1.618,2.391,2.75,4.293,2.75 c1.9,0,3.534-1.132,4.291-2.75h0.787c1.656,0,3-1.343,3-3v-2.496C44.75,22.737,41.516,19.272,37.409,18.905z M8.087,32.395 c-1.084,0-1.963-0.879-1.963-1.963s0.879-1.964,1.963-1.964s1.963,0.88,1.963,1.964S9.171,32.395,8.087,32.395z M26.042,21.001 V15.57v-2.999h3.876l5.264,8.43H26.042z M36.671,32.395c-1.084,0-1.963-0.879-1.963-1.963s0.879-1.964,1.963-1.964 s1.963,0.88,1.963,1.964S37.755,32.395,36.671,32.395z',
  fillColor: 'blue',
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0,
  scale: 0.8,
  anchor: null,
} as google.maps.Symbol

export const PICKUP_GIFT_ICO = {
  path: 'M13.5 7.5H1.5M13.5 7.5C14.0523 7.5 14.5 7.05228 14.5 6.5V5.5C14.5 4.94772 14.0523 4.5 13.5 4.5H1.5C0.947715 4.5 0.5 4.94772 0.5 5.5V6.5C0.5 7.05228 0.947716 7.5 1.5 7.5M13.5 7.5V12.5C13.5 13.6046 12.6046 14.5 11.5 14.5H3.5C2.39543 14.5 1.5 13.6046 1.5 12.5L1.5 7.5M7.5 4.5V3.5M7.5 4.5H4.21429C3.26751 4.5 2.5 3.73249 2.5 2.78571V2.5C2.5 1.39543 3.39543 0.5 4.5 0.5C6.15685 0.5 7.5 1.84315 7.5 3.5M7.5 4.5H10.7857C11.7325 4.5 12.5 3.73249 12.5 2.78571V2.5C12.5 1.39543 11.6046 0.5 10.5 0.5C8.84315 0.5 7.5 1.84315 7.5 3.5M7.5 4.5V14.5',
  fillColor: 'green',
  fillOpacity: 0.8,
  strokeWeight: 1,
  rotation: 0,
  scale: 2.1,
  anchor: null,
} as google.maps.Symbol

export const DELIVERY_GIFT_ICO = {
  path: 'M13.5 7.5H1.5M13.5 7.5C14.0523 7.5 14.5 7.05228 14.5 6.5V5.5C14.5 4.94772 14.0523 4.5 13.5 4.5H1.5C0.947715 4.5 0.5 4.94772 0.5 5.5V6.5C0.5 7.05228 0.947716 7.5 1.5 7.5M13.5 7.5V12.5C13.5 13.6046 12.6046 14.5 11.5 14.5H3.5C2.39543 14.5 1.5 13.6046 1.5 12.5L1.5 7.5M7.5 4.5V3.5M7.5 4.5H4.21429C3.26751 4.5 2.5 3.73249 2.5 2.78571V2.5C2.5 1.39543 3.39543 0.5 4.5 0.5C6.15685 0.5 7.5 1.84315 7.5 3.5M7.5 4.5H10.7857C11.7325 4.5 12.5 3.73249 12.5 2.78571V2.5C12.5 1.39543 11.6046 0.5 10.5 0.5C8.84315 0.5 7.5 1.84315 7.5 3.5M7.5 4.5V14.5',
  fillColor: 'red',
  fillOpacity: 0.8,
  strokeWeight: 1,
  rotation: 0,
  scale: 2.1,
  anchor: null,
} as google.maps.Symbol


try {
  PICKUP_FROM_ICON.anchor = new google.maps.Point(46, 89)
  DELIVERY_TO_ICON.anchor = new google.maps.Point(46, 89)
  DRIVER_ICO.anchor = new google.maps.Point(46, 89)
  PICKUP_GIFT_ICO.anchor = new google.maps.Point(7.5, 15)
  DELIVERY_GIFT_ICO.anchor = new google.maps.Point(7.5, 15)
} catch (error) {
  console.error('Error initializing anchor:', error)
}
