import "./Header2.scss"
import "./slide.scss"
import { useMediaQuery } from 'react-responsive'
import { Breakpoint } from "../../../config/breakpoints"
import { useCallback, useEffect, useRef, useState } from "react"
import Footer from "../../../atoms/Footer/Footer"
import { createPortal } from "react-dom"
import {
  LOGIN__PATH, USER__ORDER_CREATE__PATH,
} from "../../../config/page_paths"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../redux/store"
import Cookies from "js-cookie";
import { removeStoredUser } from "../../../redux/slices/userSlice";
import { getCookieName } from "../../../config/env";
import { useUser } from "../../layout/Layout/Layout";
import { UserRole } from "../../../models/user/UserRole"

const NavigationType = {
  desktop: "desktop",
  mobile: "mobile"
} as const

const MenuItems = [
  {
    id: 1,
    name: "Rate & Ship",
    path: USER__ORDER_CREATE__PATH,
  }
]

export default function Header2() {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })
  const dispatch = useDispatch<AppDispatch>()
  const userId = useSelector((state: RootState) => state.user.user_id)
  const userSelectedRole = useSelector((state: RootState) => state.user.selected_role)
  const userName = useSelector((state: RootState) => state.user.name)
  const { mutateUser } = useUser()
  const [isUserMenuOpen, setUserMenuOpen] = useState(false)
  const menuRef = useRef<HTMLButtonElement | null>(null);

  const handleNavigationButtonClick = () => {
    setMenuOpen((prev) => !prev);
  }

  const handleUserButtonClick = () => {
    setUserMenuOpen((prev) => !prev);
  }

  const handleOutsideUserMenuClick = useCallback((event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setUserMenuOpen(false);
    }
  }, []);

  const handleLogoutClick = () => {
    Cookies.remove(getCookieName() || "")
    localStorage.removeItem("token")
    mutateUser && mutateUser()
    dispatch(removeStoredUser())
    setMenuOpen(false);
  }

  const buildLogoImage = () => {
    switch (userSelectedRole) {
      case UserRole.USER:
        return <img src={isDesktopScreen ? "/images/logos/logo_black_desktop.svg" : "/images/logos/logo_black_mobile.svg"} alt="Logo"/>
      case UserRole.MANAGER:
        return <img src={isDesktopScreen ? "/images/logos/logo_manager_desktop.svg" : "/images/logos/logo_manager_mobile.svg"} alt="Logo"/>
      case UserRole.DRIVER:
        return <img src={isDesktopScreen ? "/images/logos/logo_driver_desktop.svg" : "/images/logos/logo_driver_mobile.svg"} alt="Logo"/>
      case UserRole.ADMIN:
        return <img src={isDesktopScreen ? "/images/logos/logo_admin_desktop.svg" : "/images/logos/logo_admin_mobile.svg"} alt="Logo"/>
    }
  }

  const buildMenuItems = (type: keyof typeof NavigationType) => {
    return MenuItems.map((item, index) => {
      const isActive = item.name === "Rate & Ship"
      return (
        <li 
          key={index} 
          className={
            isActive
            ? `header__navigation__list-item-${NavigationType[type]} header__navigation__list-item-active-${NavigationType[type]}`
            : `header__navigation__list-item-${NavigationType[type]}`
          }
        >
          {
            isActive && type === NavigationType.mobile 
            ? <span className="header__navigation__list-item-arrow-mobile"/> 
            : "" 
          }
          <Link 
            className="header__navigation__list-item_link" 
            to={item.path}
            onClick={() => setMenuOpen(false)}
          >
            {item.name}
          </Link>
        </li>
      )
    })
  }
  
  const buildNavigation = (type: keyof typeof NavigationType) => {
    let navigationContent = (
      <div key="nav" className={`header__header_wrapper__right-${NavigationType[type]}`}>
        <nav className={`header__navigation-${NavigationType[type]}`}>
          <ul className={`header__navigation__list-${NavigationType[type]}`}>
            {buildMenuItems(type)}
          </ul>
        </nav>
        <div className={`header__navigation__login-${NavigationType[type]}`}>
          {!userId && 
            <Link 
              className="header__navigation__list-item_link-login" 
              to={LOGIN__PATH} 
              onClick={() => setMenuOpen(false)}
            >
              Sign In / Up
            </Link>
          }
          {(userId && isDesktopScreen) && 
            <>
              <button 
                className="header__navigation__user"
                onClick={handleUserButtonClick}
              >
                <span className="header__navigation__user_avatar">
                  {userName 
                    ? userName[0]?.toUpperCase() 
                    : <img src="/images/login/image_user.svg" width={48} height={48} alt="user image"/>
                  }
                </span>
                <span className="header__navigation__user_name">{userName}</span>
              </button>
              {isUserMenuOpen && 
                <nav ref={menuRef} className="header__navigation__user_menu">
                  <ul className="header__navigation__user_menu_wrapper">
                    <li 
                      className="header__navigation__user_menu_item header__navigation__user_menu_item-logout"
                      onClick={handleLogoutClick}
                    >
                      Log Out
                    </li>
                  </ul>
                </nav>
              }
            </>
          }
          {(userId && !isDesktopScreen) && 
            <div className="header__navigation__user_menu-mobile">
              <div className="header__navigation__user_menu-mobile_wrapper">
                <span className="header__navigation__user_avatar-mobile">
                  {userName 
                    ? userName[0]?.toUpperCase() 
                    : <img src="/images/login/image_user_mobile.svg" width={28} height={28} alt="user image"/>
                  }
                </span>
                <span className="header__navigation__user_name-mobile">{userName}</span>
              </div>
              <span
                className="header__navigation__user_menu_item-mobile"
                onClick={handleLogoutClick}
              >
                Log Out
              </span>
            </div>
          }
        </div>
        {!isDesktopScreen ? <Footer /> : ""}
      </div>
    )

    if (!isDesktopScreen) {
      return createPortal(
          <div>{navigationContent}</div>,
        document.body
      );
    }

    return navigationContent
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (isUserMenuOpen) {
      document.addEventListener("mousedown", handleOutsideUserMenuClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideUserMenuClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideUserMenuClick);
  }, [isUserMenuOpen, handleOutsideUserMenuClick]);

  return (
    <header className="header">
      <div className="header__header_wrapper">
        <div className="header__header_wrapper__left">
          <div className="header__logo">
            <a href="/" className="header_logo_link">
              {buildLogoImage()}
            </a>
          </div>
        </div>
        {isDesktopScreen && buildNavigation(NavigationType.desktop)}
        {(!isDesktopScreen && isMenuOpen) && buildNavigation(NavigationType.mobile)}
        {
          isDesktopScreen 
          ? ""
          : <button 
              className={isMenuOpen ? "header__navigation_button header__navigation_button-close" : "header__navigation_button header__navigation_button-open"}
              aria-label={isMenuOpen ? "Close" : "Open" }
              onClick={handleNavigationButtonClick}
            ></button>
        }
      </div>
    </header>
)}
