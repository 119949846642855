import "./OrderCreate_07_PaymentSetup.scss"

import UserStripeSetupIntent from "../../../../../user/user/UserStripeSetupIntent/UserStripeSetupIntent/UserStripeSetupIntent"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import { useEffect, useRef, useState } from "react"
import { PageStage, PageStep } from "../../PageState"
import {
  setActiveComplete,
  setActiveLogin,
  setActivePayment,
  setActiveSummaryAndAgreement,
  setStage,
  setStep
} from "../../../../../../redux/slices/stepSlice"
import { AnimatePresence, motion } from "framer-motion"
import { useUser } from "../../../../../layout/Layout/Layout"
import { orderUpdate_confirmAndHold } from "../../../../../../api/order/order_api"
import { OrderUpdate_ConfirmAndAgreementRequest } from "../../../../../../models/order/Order"

interface Props {
  mutateOrder?: () => void
}

export default function OrderCreate_07_PaymentSetup({mutateOrder}: Props) {
  const isActivePayment = useSelector((state: RootState) => state.step.isActivePayment)
  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const dispatch = useDispatch<AppDispatch>()
  const stepRef = useRef<HTMLDivElement>(null)
  const order = useSelector((state: RootState) => state.order)
  const {user} = useUser()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (currentStep === PageStep.Payment && stepRef.current) {
      const offsetTop = stepRef.current?.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 200,
        behavior: "smooth",
      })
    }
  }, [currentStep])

  function handleSubmitButton() {
    setLoading(true)
    orderUpdate_confirmAndHold(
      order.order_id,
      window.location.href, { agreement_ids: [] } as OrderUpdate_ConfirmAndAgreementRequest // FIXME
    ).then(() => {
      setLoading(false)
      dispatch(setStage(PageStage.Confirm))
      dispatch(setStep(PageStep.Complete))

      dispatch(setActiveSummaryAndAgreement(true))
      dispatch(setActiveLogin(false))
      dispatch(setActivePayment(false))
      dispatch(setActiveComplete(true))
    })
  }

  return (
    <AnimatePresence>
      {isActivePayment && mutateOrder &&
        <motion.section
          className="user_order_create__payment_setup__section"
          ref={stepRef}
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <h3 className="user_order_create__payment_setup__title">{!user?.stripe_data ? "Add a " : ""}Payment Method</h3>
            {!user?.stripe_data &&
              <p className="user_order_create__payment_setup__text">Please fill out the Stripe payment form to finalize your order</p>
            }
            <div className="user_order_create__payment_setup__wrapper">
              {user && !user.stripe_data
                ? <UserStripeSetupIntent order={order} mutateOrder={mutateOrder}/>
                : <>
                  <p className="user_order_create__payment_setup__reuse">We will use your previously saved payment method for this order</p>
                  <button className="user_order_create__payment_setup__submit-reuse" onClick={handleSubmitButton}>
                  {isLoading ? (
                      <motion.span
                        animate={{ opacity: [1, 0.3, 1] }}
                        transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
                      >
                        Loading...
                      </motion.span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </>
              }
            </div>
        </motion.section>
      }
    </AnimatePresence>
  )
}
