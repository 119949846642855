import { LineColor, PolylineData } from './PolylineData'
import { PolygonColor, PolygonData } from './PolygonData'
import { DELIVERY_GIFT_ICO, DELIVERY_TO_ICON, buildIcon, DRIVER_ICO, Marker, PICKUP_FROM_ICON, PICKUP_GIFT_ICO, DELIVERY_SVG, PICKUP_SVG } from './Marker'
import { MarkerType } from './MarkerType'
import { BaseOrder } from '../../models/order/BaseOrder'
import { Coordinate } from '../../models/Coordinate'
import { Order } from "../../models/order/Order"

const CURRENT_LINE_COLOR = { strokeColor: 'black', strokeOpacity: 0.7, strokeWeight: 5.0 } as LineColor

const AVAILABLE_LINE_COLOR = { strokeColor: 'green', strokeOpacity: 0.7, strokeWeight: 4.0 } as LineColor

const ACTIVE_LINE_COLOR = { strokeColor: 'blue', strokeOpacity: 0.7, strokeWeight: 5.0 } as LineColor

const CURRENT_POLYGON_COLOR = { strokeColor: '#FF0000', strokeOpacity: 2.8, strokeWeight: 2, fillColor: '#FF0000', fillOpacity: 0 } as PolygonColor

export function buildEncodedPolylines(currentPolyline?: string, availablePolylines?: string[], activePolylines?: string[]): PolylineData[] {
  const polylines: PolylineData[] = []

  if (currentPolyline) {
    polylines.push({ encodedPolyline: currentPolyline, lineColor: CURRENT_LINE_COLOR } as PolylineData)
  }

  availablePolylines?.forEach(availablePolyline => {
    polylines.push({ encodedPolyline: availablePolyline, lineColor: AVAILABLE_LINE_COLOR } as PolylineData)
  })

  activePolylines?.forEach(activePolyline => {
    polylines.push({ encodedPolyline: activePolyline, lineColor: ACTIVE_LINE_COLOR } as PolylineData)
  })

  return polylines
}

export function buildEncodedPolygons(polygons: string[]): PolygonData[] {
  return polygons.map(polygon => {
    return { encodedPolygon: polygon, polygonColor: CURRENT_POLYGON_COLOR } as PolygonData
  })
}


function buildPickupMarker(entityId: string, lat: number, lng: number, type: MarkerType, payload?: any,): Marker {
  return {
    markerId: `${entityId}_pickup`,
    entityId: entityId,
    lat: lat,
    lng: lng,
    label: '',
    color: 'green',
    url: '',
    type: type,
    icon: type === MarkerType.AVAILABLE_ORDER ? PICKUP_GIFT_ICO : buildIcon(PICKUP_SVG, 60),
    payload: payload,
  }
}

function buildDeliveryMarker(entityId: string, lat: number, lng: number, type: MarkerType, payload?: any,): Marker {
  return {
    markerId: `${entityId}_delivery`,
    entityId: entityId,
    lat: lat,
    lng: lng,
    label: '',
    color: 'red',
    url: '',
    type: type,
    icon: type === MarkerType.AVAILABLE_ORDER ? DELIVERY_GIFT_ICO : buildIcon(DELIVERY_SVG, 40),
    payload: payload,
  }
}

function buildDriverMarker(entityId: string, coordinate: Coordinate, payload?: any,): Marker {
  return {
    markerId: `${entityId}_driver`, entityId: entityId, lat: coordinate.lat, lng: coordinate.lng, label: '', color: 'red', url: '', type: MarkerType.UNIT, icon: DRIVER_ICO, payload: payload,
  }
}

export function buildPolylines(order?: BaseOrder | Order): PolylineData[] | undefined {
  if (order && order.distance_data) {
    return buildEncodedPolylines(order.distance_data.encoded_polyline)
  }
}

export function buildMarkers(currentOrders?: BaseOrder[] | Order[], availableOrders?: BaseOrder[],): Marker[] {
  const markers: Marker[] = []
  currentOrders?.forEach((order: BaseOrder | Order) => {

    if (order.pickup_from && order.pickup_from.coordinate) {
      markers.push(buildPickupMarker(order.order_id, order.pickup_from.coordinate.lat, order.pickup_from.coordinate.lng, MarkerType.CURRENT_ORDER, { 'order': order },))
    }

    if (order.delivery_to && order.delivery_to.coordinate) {
      markers.push(buildDeliveryMarker(order.order_id, order.delivery_to.coordinate.lat, order.delivery_to.coordinate.lng, MarkerType.CURRENT_ORDER, { 'order': order },))
    }

    if (order.driver_coordinate) {
      buildDriverMarker(order.order_id, order.driver_coordinate, { 'order': order },)
    }
  })

  availableOrders?.forEach((order: BaseOrder) => {

    if (order.pickup_from && order.pickup_from.coordinate) {
      markers.push(
        buildPickupMarker(
          order.order_id,
          order.pickup_from.coordinate.lat,
          order.pickup_from.coordinate.lng,
          MarkerType.AVAILABLE_ORDER,
          { 'order': order },
        )
      )
    }

    // markers.push(
    //   buildDeliveryMarker(
    //     order.order_id,
    //     order.delivery_to.coordinate.lat,
    //     order.delivery_to.coordinate.lng,
    //     MarkerType.AVAILABLE_ORDER,
    //     { 'order': order },
    //   )
    // )

  })

  return markers
}
