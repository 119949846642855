import "./OrderCreate.scss"
import { Order } from "../../../../../models/order/Order"
import OrderCreate_01_Address from "./OrderCreate_01_Address/OrderCreate_01_Address"
import OrderCreate_02_Package from "./OrderCreate_02_Package/OrderCreate_02_Package"
import OrderCreate_03_DeliveryDetails from "./OrderCreate_03_DeliveryDetails/OrderCreate_03_DeliveryDetails"
import OrderCreate_06_Login from "./OrderCreate_06_Login/OrderCreate_06_Login"
import OrderCreate_07_PaymentSetup from "./OrderCreate_07_PaymentSetup/OrderCreate_07_PaymentSetup"
import OrderCreate_05_Summary from "./OrderCreate_05_Summary/OrderCreate_05_Summary"
import OrderCreate_04_Photos from "./OrderCreate_04_Photos/OrderCreate_04_Photos"
import OrderCreate_08_Complete from "./OrderCreate_08_Complete/OrderCreate_08_Complete"
import OrderCreate_Footer from "./OrderCreate_Footer/OrderCreate_Footer"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../redux/store"
import { clearOrder, updateOrder } from "../../../../../redux/slices/orderSlice"
import {
  setActiveAddresses,
  setActiveComplete,
  setActiveLogin,
  setActivePackages,
  setActivePayment,
  setActivePhotos,
  setActivePickupAndDeliveryDetails,
  setActiveSummaryAndAgreement,
  setStage,
  setStep
} from "../../../../../redux/slices/stepSlice"
import { PageStage, PageStep } from "../PageState"
import { useUser } from "../../../../layout/Layout/Layout"
import { orderUpdate_setUser } from "../../../../../api/order/order_api"
import { OrderStatus } from "../../../../../models/order/OrderStatus"
import { useNavigate } from "react-router-dom"
import { MAIN__PATH } from "../../../../../config/page_paths"

interface Props {
  order?: Order
  setOrder: (order: Order) => void
  mutateOrder: () => void
}

export default function OrderCreate({ order, setOrder, mutateOrder }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const reduxOrder = useSelector((state: RootState) => state.order)
  const currentStage = useSelector((state: RootState) => state.step.currentStage)
  const navigate = useNavigate()
  const { user, mutateUser } = useUser()

  // set order USER
  useEffect(() => {
    if (user) {
      if (reduxOrder.order_id && !reduxOrder.user_id) {
        console.log("OrderCreate.useEffect[user] + reduxOrder.order_id && !reduxOrder.user_id")
        orderUpdate_setUser(reduxOrder.order_id)
      }
    } else {
      console.log("OrderCreate.useEffect[!user].mutateUser")
      mutateUser && mutateUser()
    }
  }, [user])

  function setInactiveFillSteps() {
    dispatch(setActiveAddresses(false))
    dispatch(setActivePackages(false))
    dispatch(setActivePickupAndDeliveryDetails(false))
    dispatch(setActivePhotos(false))
  }

  function setInactiveConfirmSteps() {
    dispatch(setActiveSummaryAndAgreement(false))
    dispatch(setActiveLogin(false))
    dispatch(setActivePayment(false))
    dispatch(setActiveComplete(false))
  }

  useEffect(() => {
    if (order) {
      dispatch(updateOrder(order))
      if (order.status === OrderStatus.NEW__ADDRESS_AND_PACKAGE_DONE) {
        dispatch(setStage(PageStage.Fill))
        dispatch(setStep(PageStep.PickupAndDeliveryDetails))

        dispatch(setActiveAddresses(true))
        dispatch(setActivePackages(true))
        dispatch(setActivePickupAndDeliveryDetails(true))
        dispatch(setActivePhotos(false))

        setInactiveConfirmSteps()
      } else if (order.status === OrderStatus.NEW__DETAILS_DONE) {
        dispatch(setStage(PageStage.Fill))
        dispatch(setStep(PageStep.Photos))

        dispatch(setActiveAddresses(true))
        dispatch(setActivePackages(true))
        dispatch(setActivePickupAndDeliveryDetails(true))
        dispatch(setActivePhotos(true))

        setInactiveConfirmSteps()
      } else if (order.status === OrderStatus.NEW__PHOTOS_DONE) {
        dispatch((setStage(PageStage.Confirm)))
        if (!user) {
          dispatch(setStep(PageStep.Login))

          setInactiveFillSteps()

          dispatch(setActiveSummaryAndAgreement(true))
          dispatch(setActiveLogin(true))
          dispatch(setActivePayment(false))
          dispatch(setActiveComplete(false))
        } else {
          dispatch(setStep(PageStep.Payment))

          setInactiveFillSteps()

          dispatch(setActiveSummaryAndAgreement(true))
          dispatch(setActiveLogin(false))
          dispatch(setActivePayment(true))
          dispatch(setActiveComplete(false))
        }
      } else if (order.status === OrderStatus.PAYMENT_SETUP_REQUESTED
        || order.status === OrderStatus.PAYMENT_SETUP_FAILED
        || order.status === OrderStatus.PAYMENT_SETUP_SUCCESS
        || order.status === OrderStatus.PAYMENT_HOLD_REQUESTED
        || order.status === OrderStatus.PAYMENT_HOLD_FAILED) {
        dispatch(setStage(PageStage.Confirm))
        dispatch(setStep(PageStep.Payment))

        setInactiveFillSteps()

        dispatch(setActiveSummaryAndAgreement(true))
        dispatch(setActiveLogin(false))
        dispatch(setActivePayment(true))
        dispatch(setActiveComplete(false))
      } else if (order.status === OrderStatus.PAYMENT_HOLD_SUCCESS) {
        dispatch(setStage(PageStage.Confirm))
        dispatch(setStep(PageStep.Complete))

        setInactiveFillSteps()

        dispatch(setActiveSummaryAndAgreement(true))
        dispatch(setActiveLogin(false))
        dispatch(setActivePayment(false))
        dispatch(setActiveComplete(true))
      } else if (order.status === OrderStatus.ASSIGNED
        || order.status === OrderStatus.PICKED_UP
        || order.status === OrderStatus.DELIVERED
        || order.status === OrderStatus.PAYMENT_CAPTURE_REQUESTED
        || order.status === OrderStatus.PAYMENT_CAPTURE_FAILED
        || order.status === OrderStatus.PAYMENT_CAPTURE_SUCCESS
      ) {
        dispatch(clearOrder())

        dispatch(setStage(PageStage.Fill))
        dispatch(setStep(PageStep.Addresses))

        setInactiveFillSteps()

        setInactiveConfirmSteps()
        navigate(MAIN__PATH)
      } else {
        throw new Error(`order & ${order.status}`)
      }
    } else {
      // Rest all
      dispatch(clearOrder())

      dispatch(setStage(PageStage.Fill))
      dispatch(setStep(PageStep.Addresses))

      dispatch(setActiveAddresses(true))
      dispatch(setActivePackages(false))
      dispatch(setActivePickupAndDeliveryDetails(false))
      dispatch(setActivePhotos(false))

      setInactiveConfirmSteps()
    }
  }, [order])

  return <div className="user_order_create">
    <div className="user_order_create__content">
      {
        currentStage === PageStage.Fill || currentStage === PageStage.Edit
          ? <>
            <OrderCreate_01_Address/>
            <OrderCreate_02_Package/>
            <OrderCreate_03_DeliveryDetails/>
            <OrderCreate_04_Photos/>
          </>
          : <>
            <OrderCreate_05_Summary/>
            <OrderCreate_06_Login/>
            <OrderCreate_07_PaymentSetup mutateOrder={mutateOrder}/>
            <OrderCreate_08_Complete/>
          </>
      }
    </div>
    <div className="user_order_create__footer">
      <OrderCreate_Footer/>
    </div>
  </div>
}
