import './AgreementAdminsCreatePage.css'
import AdminAgreementCreate from '../../../../components/agreement/admin/AdminAgreementCreate/AdminAgreementCreate'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'

export default function AgreementAdminsCreatePage() {
  return <div className='agreement_admin___create_page'>
    <AdminTab tabIndex={AdminTabConfig.Agreements.index}/>
    <AdminAgreementCreate/>
  </div>
}
