/* eslint-disable react/jsx-pascal-case */
import "./OrderDetails.scss"
import { UserOrder } from "../../../models/order/UserOrder"
import { useEffect, useState } from "react";
import { userOrderFileDownload } from "../../../api/order/user_order_api";

export default function OrderDetails({ order }: { order: UserOrder }) {
	const [photoSrc, setPhotosSrc] = useState<string[] | undefined>([]);

	const getPhoto = async (photo: any) => {
		const photoBlob: Blob | undefined = await userOrderFileDownload(order.order_id, photo.file_id)
		if (!photoBlob) {
			console.error("Photo URL is undefined")
			return
		}
		return URL.createObjectURL(photoBlob)
	}

	const buildPickupDeliveryDetails = (title: string, address: string, dateTime?: string, phoneNumber?: string ) => {
		return (
			<div className="order_details__detail_wrapper">
				<h5 className="order_details__detail_title">
						{title}
				</h5>
				<p className="order_details__detail_data">
					From: {address}
				</p>
				<p className="order_details__detail_data">
						At: {
					!dateTime 
						? "As Soon As Possible"
						: dateTime
				}
				</p>
				<p className="order_details__detail_data">
						{ phoneNumber
						? "Contactless"
						: "Pickup Contact: " + phoneNumber
				}
				</p>
			</div>
		)
	}

	const buildOrderPackages = () => {
		return order.packages.map((packageItem) => {
			return (
				<li className="order_details__detail_package">
					{packageItem.length_in}x{packageItem.width_in}x{packageItem.height_in} in, {packageItem.weight_lb}
				</li>
			)
		})
	}

	const buildPhotos = () => {
		if (photoSrc?.length === 0) {
      return <p className="order_details__detail_data">No photos uploaded</p>
    }
		return photoSrc?.map((src, index) => {
			return (
				<img
					src={src}
					alt={`Uploaded Preview ${index}`}
					className="order_details__detail_photo"
				/>
			)
		})
	}

	useEffect(() => {
		const initializePhotos = async () => {
			if (!order.files?.photos_on_creation) {
				setPhotosSrc([])
				return
			} 

			try {
				const processedPhotos = await Promise.all(
					order.files.photos_on_creation.map((photo) => getPhoto(photo))
				)
				setPhotosSrc(processedPhotos.filter(Boolean) as string[])
			} catch(error) {
				console.error("Error processing photos:", error);
				setPhotosSrc([]);
			}
		}

		initializePhotos()
	}, [order.files?.photos_on_creation])

  return ( 
    <section className="order_details__section">
			{buildPickupDeliveryDetails(
				"Pickup",
				order.pickup_from.full_address,
				order.pickup_at_input,
				order.pickup_phone_number
			)}
			{buildPickupDeliveryDetails(
				"Delivery",
				order.delivery_to.full_address,
				order.delivery_at_input,
				order.delivery_phone_number
			)}
			<div className="order_details__detail_wrapper">
				<h5 className="order_details__detail_title">Package(s)</h5>
				 <ol className="order_details__detail_packages">
					{buildOrderPackages()}
				 </ol>
			</div>
			<div className="order_details__detail_wrapper">
				<h5 className="order_details__detail_title">Photo</h5>
				<ul className="order_details__detail_photos">
					{buildPhotos()}
				</ul>
			</div>
			<div className="order_details__detail_rate">
				<h5 className="order_details__detail_title">Total: </h5>
				<p className="order_details__detail_title">${order.user_rate}</p>
			</div>
		</section>
)}