import "./text.scss"
import { CSSProperties, ReactNode } from "react"
import { Variant } from "@mui/material/styles/createTypography"

interface Props {
  content?: string | number
  children?: ReactNode
  paragraph?: boolean
  variant?: Variant
  color?: string
  gutterBottom?: boolean
  sx?: CSSProperties,
  className?: string
}

export default function Text_({ content, children, paragraph, variant, color, gutterBottom, sx, className }: Props) {
  return <>
    <div className={className}>
      {children}
    </div>
    {/*<Typography*/}
    {/*  component={paragraph ? "p" : "div"}*/}
    {/*  variant={variant}*/}
    {/*  color={color}*/}
    {/*  style={sx}*/}
    {/*  className={className}*/}
    {/*  gutterBottom={gutterBottom}*/}
    {/*>*/}
    {/*  {content || children}*/}
    {/*</Typography>*/}
  </>
}
