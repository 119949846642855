import './style.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { userOrderFileDownload } from '../../../api/order/user_order_api'
import { useDebugState } from 'use-named-state'
import { OrderFiles } from '../../../models/order/OrderFiles'
import { useEffect } from 'react'
import axios from 'axios'
import { PhotoAndLabel } from "../../../atoms/SwipeableView/PhotoAndLabel"
import SwipeableView from "../../../atoms/SwipeableView/SwipeableView"


interface Props {
  orderId: string
  files: OrderFiles
}

export default function OrderFilesSwipeableView({ orderId, files }: Props) {
  const [photosAndLabels, setPhotosAndLabels] = useDebugState<PhotoAndLabel[]>('photosAndLabels', [])

  // useEffect(() => {
  //   console.log("useEffect: OrderFilesSwipeableView")
  //   const fetchImages = async () => {
  //     try {
  //       const photosAndLabel: PhotoAndLabel[] = []

  //       const creationPhotoRequests: Promise<string | undefined>[] = []
  //       files.photos_on_creation?.forEach(orderPhoto => {
  //         creationPhotoRequests.push(userOrderFileDownload(orderId, orderPhoto.file_id))
  //       })
  //       const creationPhotoResponses = await axios.all(creationPhotoRequests)
  //       creationPhotoResponses.forEach(response => {
  //         if (response) {
  //           photosAndLabel.push({
  //             binary: response, label: 'Photos on Creation',
  //           })
  //         }
  //       })

  //       const pickupPhotoRequests: Promise<string | undefined>[] = []
  //       files.photos_on_pickup?.forEach(orderPhoto => {
  //         pickupPhotoRequests.push(userOrderFileDownload(orderId, orderPhoto.file_id))
  //       })
  //       const pickupPhotoResponses = await axios.all(pickupPhotoRequests)
  //       pickupPhotoResponses.forEach(response => {
  //         if (response) {
  //           photosAndLabel.push({
  //             binary: response, label: 'Photos on Pickup',
  //           })
  //         }
  //       })

  //       const deliveryPhotoRequests: Promise<string | undefined>[] = []
  //       files.photos_on_complete?.forEach(orderPhoto => {
  //         deliveryPhotoRequests.push(userOrderFileDownload(orderId, orderPhoto.file_id))
  //       })
  //       const deliveryPhotoResponses = await axios.all(deliveryPhotoRequests)
  //       deliveryPhotoResponses.forEach(response => {
  //         if (response) {
  //           photosAndLabel.push({
  //             binary: response, label: 'Photos on Delivery',
  //           })
  //         }
  //       })

  //       setPhotosAndLabels(photosAndLabel)
  //     } catch (error) {
  //       console.error('Error fetching images:', error)
  //     }
  //   }

  //   fetchImages()
  // }, [])

  return <div className='order_files_swipeable_view'>
    <SwipeableView photosAndLabels={photosAndLabels}/>
  </div>
}
