import { configureStore, createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"
import orderReducer, {
  setDeliveryPhoneNumber,
  setDeliveryAtInput,
  setPackages,
  setPickupPhoneNumber,
  setPickupAtInput,
  updateAddressFrom,
  updateAddressTo,
  updateOrder, cleanPickupAtInput, cleanDeliveryAtInput, cleanPickupPhoneNumber, cleanDeliveryPhoneNumber
} from "./slices/orderSlice"
import stepReducer from "./slices/stepSlice"
import userReducer from "./slices/userSlice"
import filesReducer, { setCreateFiles } from "./slices/filesSlice"
import { getBackendUrl } from "../config/env"
import { USER__ORDER_CREATE__PATH } from "../config/page_paths"
import { orderUpdate_CreatePhotos } from "../api/order/order_api"

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: isAnyOf(
    updateAddressTo, updateAddressFrom,
    setPackages,
    setPickupAtInput, cleanPickupAtInput,
    setPickupPhoneNumber, cleanPickupPhoneNumber,
    setDeliveryAtInput, cleanDeliveryAtInput,
    setDeliveryPhoneNumber, cleanDeliveryPhoneNumber,
    setCreateFiles,
  ),
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState
    let url
    let response
    try {
      if (setCreateFiles.match(action)) {
        const filesOnCreation = state.files.files_on_creation;
        orderUpdate_CreatePhotos(state.order.order_id, filesOnCreation).then((response) => {
          listenerApi.dispatch(updateOrder(response))
        })
      } else {
        if (!state.order.order_id) {
          url = `${getBackendUrl()}/v2/orders/draft`
          response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(state.order, (_, value) => (value === undefined ? null : value))
          })
        } else {
          url = `${getBackendUrl()}/v2/orders/${state.order.order_id}`
          response = await fetch(url, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(state.order, (_, value) => (value === undefined ? null : value))
          })
        }
      }
      if (!response?.ok) throw new Error(`API request failed with status ${response?.status}: ${response?.statusText}`)
      const updatedOrder = await response.json()
      listenerApi.dispatch(updateOrder(updatedOrder.data))

      if (updatedOrder.data.order_id) {
        window.history.pushState(null, "", `${USER__ORDER_CREATE__PATH}/${updatedOrder.data.order_id}`)
      }

    } catch (error) {
      console.error("API update failed:", error)
    }
  }
})

export const store = configureStore({
  reducer: {
    order: orderReducer,
    step: stepReducer,
    user: userReducer,
    files: filesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch