import useSWR, { SWRResponse } from 'swr'
import { http } from '../http'
import { PageRequest, PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'
import { BaseAgreement } from '../../models/agreement/BaseAgreement'

export function useDriverAgreement(agreementId: string): SWRResponse<BaseAgreement> {
  return useSWR<BaseAgreement>([`/driver/agreements/${agreementId}`])
}

export async function driverAgreementDownload(agreementId: string): Promise<Blob | undefined> {
  return http.getFile(`/driver/agreements/${agreementId}`)
}

export function useDriverAgreements(page?: number, pageSize?: number): SWRResponse<PageResponse<BaseAgreement>> {
  return usePageable(
    '/driver/agreements/search',
    {
      page: page,
      page_size: pageSize,
    } as PageRequest<any>
  )
}
