export enum PageStep {
  Addresses, // 0 - "No order",
  Packages, // 1 - "No order; filled address",
  PickupAndDeliveryDetails, // 2 - "order.NEW__ADDRESS_AND_PACKAGE",
  Photos, // 3
  SummaryAndAgreement, // 4
  Login, // 5
  Payment, // 6
  Complete //7
}

export enum PageStage {
  Fill, // 0 - Steps: Addresses, Packages, PickupAndDeliveryDetails, Photos
  Confirm, // 1 - Steps: SummaryAndAgreement, Login, Payment, Complete
  Edit,
}
