/* eslint-disable react/jsx-pascal-case */
import "./UserDashboard.scss"
import Map_ from "../../../atoms/Map/Map"
import { buildMarkers, buildPolylines } from "../../../atoms/Map/utils"
import { useUserOrderCurrent } from '../../../api/order/user_order_api'
import { useState } from "react"
import OrderTimeline from "../../order/OrderTimeline/OrderTimeline"
import OrderDetails from "../../order/OrderDetails/OrderDetails"
import { useMediaQuery } from 'react-responsive'
import { Breakpoint } from "../../../config/breakpoints"
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { USER__ORDER_CREATE__PATH } from "../../../config/page_paths";

enum CurrentOrderTab {
  timeline,  //0
  details   //1
}

const CurrentOrderTabs = [
  {
    id: 0,
    name: "timeline",
    label: "Timeline",
  },
  {
    id: 1,
    name: "details",
    label: "Details",
  }
]

export default function UserDashboard() {
  const { data: currentOrder, mutate: mutateOrder } = useUserOrderCurrent()
  const [currentTab, setCurrentTab] = useState(CurrentOrderTab.timeline)
  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })

  const mapStyle = 
    isDesktopScreen 
    ? {width: "638px", height: "638px"}
    : {width: "338px", height: "310px"}

  const buildCurrentOrderTabs = () => {
    return CurrentOrderTabs.map((tab) => {
      return (
        <button 
          className={
            `user_dashboard__current_order_tab
            user_dashboard__current_order_tab-${tab.name}
            ${currentTab === tab.id 
              ? "user_dashboard__current_order_tab-active"
              : ""}`
          }
          onClick={() => handleTabClick(tab.id)}
        >
          {tab.label}
        </button>
      )
    })
  }

  const handleActionsClick = () => {

  }

  const handleTabClick = (tabId: number) => {
    setCurrentTab(tabId);
  }

  const navigate = useNavigate()

  const handleVisitClick = () => {
    navigate(USER__ORDER_CREATE__PATH)
  }

  return (
    <div className={`user_dashboard__section ${currentOrder ? "" : "user_dashboard__section-no_order"}`}>
      { currentOrder && <>
        <div className="user_dashboard__heading">
          <h2 className="user_dashboard__title">Order #{currentOrder.order_id}</h2>
          <button 
            className="user_dashboard__button-actions" 
            onClick={handleActionsClick} 
            aria-label="actions" 
          />
        </div>
        <div className="user_dashboard__current">
          <Map_  
            polylines={buildPolylines(currentOrder)}
            markers={buildMarkers([currentOrder])}
            className="user_dashboard__current_map"
            width={mapStyle.width}
            height={mapStyle.height}
          />
          <div className="user_dashboard__current_order">
            <div className="user_dashboard__current_order_tabs">
              {buildCurrentOrderTabs()}
            </div>
            <div className="user_dashboard__current_order_wrapper">
              <AnimatePresence mode="wait">
                { (currentTab === CurrentOrderTab.timeline) &&          
                  <motion.div 
                    key="timeline"
                    className="user_dashboard__current_order_timeline"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <OrderTimeline order={currentOrder} />
                  </motion.div>
                }
                { (currentTab === CurrentOrderTab.details) &&
                  <motion.div 
                    key="details"
                    className="user_dashboard__current_order_details"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <OrderDetails order={currentOrder} />
                  </motion.div>
                }
              </AnimatePresence>
            </div>
          </div>
        </div>
      </>
      } 
      {!currentOrder && 
        <div className="user_dashboard__no_order">
          <img className="user_dashboard__no_order_image" src="/images/dashboard/image-no-order.png" width={300} height={300} alt="No active order"/>
          <h2 className="user_dashboard__no_order_title">No active orders</h2>
          <p className="user_dashboard__no_order_hint">No orders in progress at the moment. Once you place an order, you’ll be able to track it here in real time.</p>
          <button
              onClick={handleVisitClick}
              className="user_dashboard__no_order_button"
          >
              Rate & Ship
          </button>
        </div>
      }
    </div>
)}