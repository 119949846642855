import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PageStage, PageStep } from "../../components/order/user/UserOrderCreate/PageState"

interface StepState {
  currentStage: PageStage
  currentStep: PageStep
  isActiveAddresses: boolean
  isActivePackages: boolean
  isActivePickupAndDeliveryDetails: boolean
  isActivePhotos: boolean
  isActiveSummaryAndAgreement: boolean
  isActiveLogin: boolean
  isActivePayment: boolean
  isActiveComplete: boolean
}

const initialState: StepState = {
  currentStage: PageStage.Fill,
  currentStep: PageStep.Addresses,
  isActiveAddresses: true,
  isActivePackages: false,
  isActivePickupAndDeliveryDetails: false,
  isActivePhotos: false,
  isActiveSummaryAndAgreement: false,
  isActiveLogin: false,
  isActivePayment: false,
  isActiveComplete: false,
}

const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setStage: (state, action: PayloadAction<PageStage>) => {
      state.currentStage = action.payload
    },
    setStep: (state, action: PayloadAction<PageStep>) => {
      state.currentStep = action.payload
    },
    setActiveAddresses: (state, action: PayloadAction<boolean>) => {
      state.isActiveAddresses = action.payload
    },
    setActivePackages: (state, action: PayloadAction<boolean>) => {
      state.isActivePackages = action.payload
    },
    setActivePickupAndDeliveryDetails: (state, action: PayloadAction<boolean>) => {
      state.isActivePickupAndDeliveryDetails = action.payload
    },
    setActivePhotos: (state, action: PayloadAction<boolean>) => {
      state.isActivePhotos = action.payload
    },
    setActiveSummaryAndAgreement: (state, action: PayloadAction<boolean>) => {
      state.isActiveSummaryAndAgreement = action.payload
    },
    setActiveLogin: (state, action: PayloadAction<boolean>) => {
      state.isActiveLogin = action.payload
    },
    setActivePayment: (state, action: PayloadAction<boolean>) => {
      state.isActivePayment = action.payload
    },
    setActiveComplete: (state, action: PayloadAction<boolean>) => {
      state.isActiveComplete = action.payload
    }
  }
})

export const { setStage } = stepSlice.actions
export const { setStep } = stepSlice.actions
export const { setActiveAddresses } = stepSlice.actions
export const { setActivePackages } = stepSlice.actions
export const { setActivePickupAndDeliveryDetails } = stepSlice.actions
export const { setActivePhotos } = stepSlice.actions
export const { setActiveSummaryAndAgreement } = stepSlice.actions
export const { setActiveLogin } = stepSlice.actions
export const { setActivePayment } = stepSlice.actions
export const { setActiveComplete } = stepSlice.actions

export default stepSlice.reducer

