import "./OrderCreate_08_Complete.scss"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import { useEffect, useRef } from "react"
import { PageStage, PageStep } from "../../PageState"
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { setStage, setStep } from "../../../../../../redux/slices/stepSlice";
import { clearOrder } from "../../../../../../redux/slices/orderSlice";
import { MAIN__PATH } from "../../../../../../config/page_paths";

export default function OrderCreate_08_Complete() {
  const dispatch = useDispatch<AppDispatch>()
  const isActiveComplete = useSelector((state: RootState) => state.step.isActiveComplete)
  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const stepRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate();

  const handleVisitClick = () => {
    if (currentStep === PageStep.Complete) {
      console.log(`OrderCreate_08_Complete.handleVisitClick`)
      dispatch(setStage(PageStage.Fill))
      dispatch(setStep(PageStep.Addresses))
      dispatch(clearOrder())
    }
    navigate(MAIN__PATH)
  }

  useEffect(() => {
    if (currentStep === PageStep.Complete && stepRef.current) {
      const offsetBottom = stepRef.current?.getBoundingClientRect().bottom + window.scrollY;
      window.scrollTo({
        top: offsetBottom,
        behavior: "smooth",
      })
    }
  }, [currentStep])

  return (
    <AnimatePresence>
      {isActiveComplete &&
          <motion.section
              className="user_order_create__complete__section"
              ref={stepRef}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
          >
              <div className="user_order_create__complete__wrapper">
                  <h2 className="user_order_create__complete__title">You are all set!</h2>
                  <div className="user_order_create__complete__text_wrapper">
                      <p className="user_order_create__complete__text">We're searching for the nearest driver to deliver your package.</p>
                      <p className="user_order_create__complete__text">You'll receive an update soon</p>
                  </div>
                  <button
                      className="user_order_create__complete__button"
                      onClick={handleVisitClick}
                  >
                      Visit Dashboard
                  </button>
              </div>
          </motion.section>
      }
    </AnimatePresence>
  )
}
