import "./index.scss"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { getBackendUrl } from "./config/env"
import { SWRConfig } from "swr"
import { StrictMode } from "react"
import App from "./App"
import { http } from "./api/http"
import { Provider } from "react-redux"
import { store } from "./redux/store"

const swrConfig = {
  refreshInterval: 30000, // ms = 30s
  fetcher: (url: string) => http.getData(getBackendUrl() + url)
}

createRoot(document.getElementById("root") as HTMLElement)
  .render(
    <StrictMode>
      <BrowserRouter>
        <SWRConfig value={swrConfig}>
          <Provider store={store}>
            <App/>
          </Provider>
        </SWRConfig>
      </BrowserRouter>
    </StrictMode>
  )
