export function userTimeZoneDate(inputDateString?: string, timezone?: string): string {
  if (inputDateString) {
    return new Date(inputDateString)
      .toLocaleString(undefined, {
        timeZone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      .replace(/\b([ap])m\b/g, match => match.toUpperCase())
  } else {
    return ''
  }
}

export function formatToISOString(dateStr: string): string {
  if (!dateStr || dateStr.length < 12) return ""
  return  new Date(dateStr.replace(" ", "T")).toISOString()
}

export function formatISOToDateAndTime(isoStr: string) {
  if (!isoStr) return { date: "", time: "" };

  const date = new Date(isoStr);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return {
    date: `${year}-${month}-${day}`,
    time: `${hours}:${minutes}`,
  };
}
