import './style.css'
import TextField_ from '../../../../atoms/TextField/TextField'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import Divider_ from '../../../../atoms/Divider/Divider'
import { userTimeZoneDate } from '../../../../utils/time_utils'

interface Props {
  order: BaseOrder
}

export default function OrderCardDeliveryInfo({ order }: Props) {
  return <>
    <Divider_ text='Delivery info' className='order_card__divider__delivery_info'/>
    <TextField_ label='Delivery to' value={order.delivery_to.full_address} className='order_card__delivery_to'/>
    <TextField_ label='Delivery Phone number' value={order.delivery_phone_number} className='order_card__delivery_phone_number'/>
    <TextField_ label='Delivery at, input' value={userTimeZoneDate(order.delivery_at_input)} className='order_card__delivery_at_input'/>
  </>
}
