import "./style.css"
import UserDashboardPage from "../../dashboard/user/UserDashboardPage/UserDashboardPage"
import AdminDashboardPage from "../../dashboard/admin/AdminDashboardPage/AdminDashboardPage"
import DriverDashboardPage from "../../dashboard/driver/DriverDashboardPage/DriverDashboardPage"
import { useUser } from "../../../components/layout/Layout/Layout"
import { UserRole } from "../../../models/user/UserRole"
import UserOrderCreatePage from "../../order/users/UserOrderCreatePage/UserOrderCreatePage"
import ManagerDashboardPage from "../../dashboard/manager/ManagerDashboardPage/ManagerDashboardPage"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { PageStage } from "../../../components/order/user/UserOrderCreate/PageState"

export default function MainPage() {
  const { user } = useUser()
  const currentStage = useSelector((state: RootState) => state.step.currentStage)

  function chooseMainPage(userRole?: UserRole, userSelectedRole?: UserRole) {
    const role = userSelectedRole || userRole
    if (role === undefined || currentStage === PageStage.Confirm) {
      return <UserOrderCreatePage/>
    } else if (role === UserRole.USER) {
      return <UserDashboardPage/>
    } else if (role === UserRole.ADMIN) {
      return <AdminDashboardPage/>
    } else if (role === UserRole.DRIVER) {
      return <DriverDashboardPage/>
    } else if (role === UserRole.MANAGER) {
      return <ManagerDashboardPage/>
    }
  }

  return <>
    {chooseMainPage(user?.role, user?.selected_role)}
  </>
}
