export enum OrderStatus {
  FRONTEND_DRAFT = 'FRONTEND_DRAFT',
  NEW__ADDRESS_AND_PACKAGE_DONE = 'NEW__ADDRESS_AND_PACKAGE_DONE',
  NEW__DETAILS_DONE = 'NEW__DETAILS_DONE',
  NEW__PHOTOS_DONE = 'NEW__PHOTOS_DONE',

  PAYMENT_SETUP_REQUESTED = 'PAYMENT_SETUP_REQUESTED',
  PAYMENT_SETUP_FAILED = 'PAYMENT_SETUP_FAILED',
  PAYMENT_SETUP_SUCCESS = 'PAYMENT_SETUP_SUCCESS',
  PAYMENT_HOLD_REQUESTED = 'PAYMENT_HOLD_REQUESTED',
  PAYMENT_HOLD_FAILED = 'PAYMENT_HOLD_FAILED',
  PAYMENT_HOLD_SUCCESS = 'PAYMENT_HOLD_SUCCESS',

  ASSIGNED = 'ASSIGNED',
  PICKED_UP = 'PICKED_UP',
  DELIVERED = 'DELIVERED',
  PAYMENT_CAPTURE_REQUESTED = 'PAYMENT_CAPTURE_REQUESTED',
  PAYMENT_CAPTURE_FAILED = 'PAYMENT_CAPTURE_FAILED',
  PAYMENT_CAPTURE_SUCCESS = 'PAYMENT_CAPTURE_SUCCESS',
}
