import "./address_autocomplete.scss"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { Address } from '../../models/Address'
import { CSSProperties } from "react"
import { LoaderOptions } from "@googlemaps/js-api-loader"
import { useMediaQuery } from 'react-responsive';
import { Breakpoint } from "../../config/breakpoints";

const US_REGION_GOOGLE_PLACES_REQUEST_OPTIONS = { 'region': 'us' }

interface Props {
  placesOptions?: Partial<LoaderOptions>
  placeholder: string
  className?: string
  onChange: (address: Address) => void
  style?: CSSProperties
  defaultInputValue: string
}

export default function AddressAutocomplete({ placesOptions, placeholder, className, onChange, style, defaultInputValue }: Props) {
  const toGooglePlacesResponse = (value: any) => {
    const splitAddress = value.value.terms.map((term: any) => term.value).reverse()
    return {
      full_address: value.label,
      building: splitAddress[4],
      street: splitAddress[3],
      city: splitAddress[2],
      state: splitAddress[1],
      country: splitAddress[0]
    } as Address
  }

  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop });

  const widthContainerControl = isDesktopScreen ? 500 : 400
  const widthWidthInput = isDesktopScreen ? 440 : 370
  const heightContainerControl = isDesktopScreen ? 70 : 58
  const heightWidthInput = isDesktopScreen ? 50 : 30
  const paddingInput = isDesktopScreen ? 14 : 33


  return <div className={className} style={style}>
    <GooglePlacesAutocomplete
      autocompletionRequest={{ componentRestrictions: { country: ['us'] } }}
      apiOptions={placesOptions ? placesOptions : US_REGION_GOOGLE_PLACES_REQUEST_OPTIONS}
      selectProps={{
        defaultInputValue: defaultInputValue,
        value: defaultInputValue ? { label: defaultInputValue, value: defaultInputValue } : undefined,
        onChange: (newValue: any) => onChange(toGooglePlacesResponse(newValue)),
        placeholder: placeholder,
        styles: {
          container: (provided) => ({
            ...provided,
            maxWidth: widthContainerControl,
            height: heightContainerControl,
          }),
          control: (provided, state) => ({
            ...provided,
            maxWidth: widthContainerControl,
            height: heightContainerControl,
            borderRadius: 14,
            paddingLeft: 14,
            paddingRight: paddingInput,
            border: state.isFocused ? "1px solid #121212" : "1px solid #C2C6D3",
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #121212", // Darker border on hover
            },
          }),
          input: (provided) => ({
            ...provided,
            maxWidth: widthWidthInput,
            height: heightWidthInput,
          }),
          menu: (provided) => ({
            ...provided,
          }),
          option: (provided, state) => ({
            ...provided,
          }),
        },
      }}
    />
  </div>
}

