import "./UserDashboardPage.scss"
import UserDashboard from "../../../../components/dashboard/UserDashboard/UserDashboard"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../redux/store"
import { AnimatePresence, motion } from "framer-motion"
import UserOrdersHistory from "../../../../components/order/user/UserOrdersHistory/UserOrdersHistory"

enum UserDashboardTab {
  currentOrder,  //0
  orderHistory   //1
}

const UserDashboardTabs = [
  {
    id: 0,
    name: "current",
    label: "Current Order",
  },
  {
    id: 1,
    name: "history",
    label: "Order History",
  }
]

export default function UserDashboardPage() {
  const [currentTab, setCurrentTab] = useState(UserDashboardTab.currentOrder);

  const handleTabClick = (tab: number) => {
    if (currentTab !== tab) setCurrentTab(tab) 
  }

  const buildDashboardTabs = () => {
    return UserDashboardTabs.map((tab) => {
      return (
        <motion.button 
          key={tab.id}
          className={
            `user_dashboard__tab 
            ${currentTab === tab.id 
              ? "user_dashboard__tab-active" 
              : ""
            }`
          }
          onClick={() => handleTabClick(tab.id)}
          layoutId="active-tab"
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
        > 
          <span className={
            `user_dashboard__tab_${tab.name}
            ${currentTab === tab.id 
                ? `user_dashboard__tab_${tab.name}-active`
                : ""
              }`
            }
          />
          {tab.label}
        </motion.button>
      )
    })
  }

  const buildDashboardContent = () => {
    switch (currentTab) {
      case UserDashboardTab.currentOrder:
        return <UserDashboard/>
      case UserDashboardTab.orderHistory:
        return <UserOrdersHistory />
    }
  }

  return (
    <section className="user_dashboard__page">
      <div className="user_dashboard__tabs">
        {buildDashboardTabs()}
      </div>
      <div className="user_dashboard__content-wrapper">
        <motion.div
          key={currentTab}
          className="user_dashboard__content"
          initial={{ opacity: 0}}
          animate={{ opacity: 1}}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {buildDashboardContent()}
        </motion.div>
      </div>
    </section>
  )
}
