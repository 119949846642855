import './style.css'
import { OrderStatus } from '../../../models/order/OrderStatus'
import { useTheme } from '@mui/system'
import { ReactNode } from 'react'
import { toUiOrderStatus } from '../../../utils/order_status_utils'
import { Step, StepLabel, Stepper, useMediaQuery } from "@mui/material"

interface TimelineProps {
  index: number
  name: string
  error?: boolean
}

type TimelineMap = {
  [key: string]: TimelineProps
}

const TIMELINE_MAP: TimelineMap = {
  [OrderStatus.NEW__DETAILS_DONE]: { index: 0, name: toUiOrderStatus(OrderStatus.NEW__DETAILS_DONE) },
  [OrderStatus.PAYMENT_SETUP_REQUESTED]: { index: 0, name: toUiOrderStatus(OrderStatus.PAYMENT_SETUP_REQUESTED) },
  [OrderStatus.PAYMENT_SETUP_FAILED]: { index: 0, name: toUiOrderStatus(OrderStatus.PAYMENT_SETUP_FAILED), error: true },
  [OrderStatus.PAYMENT_SETUP_SUCCESS]: { index: 1, name: toUiOrderStatus(OrderStatus.PAYMENT_SETUP_SUCCESS) },
  [OrderStatus.PAYMENT_HOLD_REQUESTED]: { index: 1, name: toUiOrderStatus(OrderStatus.PAYMENT_HOLD_REQUESTED) },
  [OrderStatus.PAYMENT_HOLD_FAILED]: { index: 1, name: toUiOrderStatus(OrderStatus.PAYMENT_HOLD_FAILED), error: true },
  [OrderStatus.PAYMENT_HOLD_SUCCESS]: { index: 2, name: toUiOrderStatus(OrderStatus.PAYMENT_HOLD_SUCCESS) },
  [OrderStatus.ASSIGNED]: { index: 3, name: toUiOrderStatus(OrderStatus.ASSIGNED) },
  [OrderStatus.PICKED_UP]: { index: 4, name: toUiOrderStatus(OrderStatus.PICKED_UP) },
  [OrderStatus.DELIVERED]: { index: 5, name: toUiOrderStatus(OrderStatus.DELIVERED) },
  [OrderStatus.PAYMENT_CAPTURE_REQUESTED]: { index: 5, name: toUiOrderStatus(OrderStatus.PAYMENT_CAPTURE_REQUESTED) },
  [OrderStatus.PAYMENT_CAPTURE_FAILED]: { index: 5, name: toUiOrderStatus(OrderStatus.PAYMENT_CAPTURE_FAILED), error: true },
  [OrderStatus.PAYMENT_CAPTURE_SUCCESS]: { index: 6, name: toUiOrderStatus(OrderStatus.PAYMENT_CAPTURE_SUCCESS) },
}


interface Props {
  orderStatus: OrderStatus
}

export default function OrderTimeline({ orderStatus }: Props) {

  function buildStep(props: TimelineProps): ReactNode {
    return (<Step key={props.name}>
      <StepLabel {...props}>{props.name}</StepLabel>
    </Step>)
  }

  function buildSteps(currentOrderStatus: OrderStatus): ReactNode[] {
    switch (currentOrderStatus) {
      case OrderStatus.NEW__DETAILS_DONE:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_SETUP_REQUESTED]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.PAYMENT_SETUP_REQUESTED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_SETUP_REQUESTED]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.PAYMENT_SETUP_FAILED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_SETUP_FAILED]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.PAYMENT_SETUP_SUCCESS:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.PAYMENT_HOLD_REQUESTED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_HOLD_REQUESTED]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.PAYMENT_HOLD_FAILED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_HOLD_FAILED]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.PAYMENT_HOLD_SUCCESS:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.ASSIGNED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.PICKED_UP:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
      case OrderStatus.DELIVERED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_CAPTURE_REQUESTED]),
        ]
      case OrderStatus.PAYMENT_CAPTURE_REQUESTED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_CAPTURE_REQUESTED]),
        ]
      case OrderStatus.PAYMENT_CAPTURE_FAILED:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_CAPTURE_FAILED]),
        ]
      case OrderStatus.PAYMENT_CAPTURE_SUCCESS:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
          buildStep(TIMELINE_MAP[OrderStatus.PAYMENT_CAPTURE_SUCCESS]),
        ]
      default:
        return [
          buildStep(TIMELINE_MAP[OrderStatus.NEW__DETAILS_DONE]),
          buildStep(TIMELINE_MAP[OrderStatus.ASSIGNED]),
          buildStep(TIMELINE_MAP[OrderStatus.PICKED_UP]),
          buildStep(TIMELINE_MAP[OrderStatus.DELIVERED]),
        ]
    }
  }

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'))

  return <div className='order_timeline'>
    <Stepper
      activeStep={TIMELINE_MAP[orderStatus]?.index}
      alternativeLabel={!isSmallScreen}
      orientation={isSmallScreen ? 'vertical' : 'horizontal'}
    >
      {buildSteps(orderStatus)}
    </Stepper>
  </div>
}
