import './style.css'
import { useDebugState } from 'use-named-state'
import { OrderStatus } from '../../../../models/order/OrderStatus'
import Button_ from '../../../../atoms/Button/Button'
import FileInput, { FileInputType } from '../../../../atoms/FileInput/FileInput'
import TextField_ from '../../../../atoms/TextField/TextField'
import { adminOrderDeliver, adminOrderMarkAsPaymentHeld, adminOrderPickup } from '../../../../api/order/admin_order_api'
import Text_ from "../../../../atoms/Text/Text"

interface Props {
  mutateOrder?: () => void
  orderId: string
  orderStatus: OrderStatus
}

export function AdminOrderActions({ mutateOrder, orderId, orderStatus }: Props) {
  const [files, setFiles] = useDebugState<FileList | undefined>('files', undefined)
  const [internalComment, setInternalComment] = useDebugState<string | undefined>('internalComment', undefined)

  function cleanUp() {
    setFiles(undefined)
    setInternalComment(undefined)
  }

  function buildActions(orderStatus: OrderStatus) {
    if (orderStatus == OrderStatus.ASSIGNED) {
      let lambda: (() => Promise<any>) | undefined = undefined
      if (files) {
        lambda = () => adminOrderPickup(orderId, { internal_comment: internalComment }, files)
          .then(() => {
            cleanUp()
            mutateOrder && mutateOrder()
          })
      }
      return <>
        <Text_ content="Attach pickup photos:"/>
        <FileInput label='Upload Photos' type={FileInputType.IMAGE} files={files} onChange={setFiles}/>
        <TextField_ label='Internal comment' value={internalComment} onChange={setInternalComment}/>
        <Button_ label='Pick up an Order' disabled={!files} onClick={lambda} logPath='AdminOrderActions.buildActions.adminOrderPickup' className="admin_order_actions__order_pickup_button"/>
      </>
    } else if (orderStatus == OrderStatus.PICKED_UP) {
      let lambda: (() => Promise<any>) | undefined = undefined
      if (files) {
        lambda = () => adminOrderDeliver(orderId, { internal_comment: internalComment }, files)
          .then(() => {
            cleanUp()
            mutateOrder && mutateOrder()
          })
      }
      return <>
        <Text_ content="Attach deliver photos:"/>
        <FileInput label='Upload Photos' type={FileInputType.IMAGE} files={files} onChange={setFiles}/>
        <TextField_ label='Internal comment' value={internalComment} onChange={setInternalComment}/>
        <Button_ label='Deliver an Order' disabled={!files} onClick={lambda} logPath='AdminOrderActions.buildActions.adminOrderDeliver'/>
      </>
    } else {
      return <></>
    }
  }

  return <div className='admin_order_actions'>
    {buildActions(orderStatus)}
  </div>
}
