import './DriverRouteCreate.scss'
import { useDebugState } from 'use-named-state'
import { useNavigate } from 'react-router-dom'
import { Address } from '../../../../models/Address'
import { DriverRouteUpsertRequest } from '../../../../models/route/DriverRoute'
import { driverRouteCreate } from '../../../../api/route/driver_route_api'
import { DRIVER__ROUTES__PATH } from '../../../../config/page_paths'
import AddressAutocomplete from '../../../../atoms/AddressAutocomplete/AddressAutocomplete'
import { useMediaQuery } from 'react-responsive';
import { Breakpoint } from "../../../../config/breakpoints";
import { ChangeEvent, useState } from 'react'
import { motion } from 'framer-motion'
import dayjs from 'dayjs'

interface RouteDetails {
  start_at_date: string,
  start_at_time: string,
  end_at_date: string,
  end_at_time: string,
  start_from: Address | undefined,
  end_to: Address | undefined,
  buffer_distance_miles: number | undefined
}

export default function DriverRouteCreate() {

  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })
  const addressConfig = { width: !isDesktopScreen ? "100%" : "480px", margin: "auto" }
  const [routeDetails, setRouteDetails] = useState<RouteDetails>({
    start_at_date: "",
    start_at_time: "",
    end_at_date: "",
    end_at_time: "",
    start_from: undefined,
    end_to: undefined,
    buffer_distance_miles: undefined
  })

  const [isValidInput, setValidInput] = useState({
    start_at_date: false,
    start_at_time: false,
    end_at_date: false,
    end_at_time: false,
    start_from: false,
    end_to: false,
    buffer_distance_miles: false,
  })  

  const navigate = useNavigate()

  const [isUploading, setIsUploading] = useDebugState<boolean>('isUploading', false)

  const startDateTime = dayjs(`${routeDetails.start_at_date} ${routeDetails.start_at_time}`).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  const endDateTime = dayjs(`${routeDetails.end_at_date} ${routeDetails.end_at_time}`).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')


  const validateSubmitRequest = () => {
    return Object.values(isValidInput).every(Boolean);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setIsUploading(true)
    const routeUpsertRequest = {
      buffer_distance_miles: routeDetails.buffer_distance_miles, routes: [{
        address: routeDetails.start_from, datetime: startDateTime,
      }, {
        address: routeDetails.end_to, datetime: endDateTime,
      },]
    } as DriverRouteUpsertRequest
    driverRouteCreate(routeUpsertRequest)
      .then(route => navigate(`${DRIVER__ROUTES__PATH}/${route.route_id}`))
      .catch(e => console.log(`DriverRouteCreate.handleSubmit.driverRouteCreate: ${e}`))
      .finally(() => setIsUploading(false))
  }

  const handleFromInputChange = (value: Address) => {
    setRouteDetails((prevState) => ({
      ...prevState,
      start_from: value,
    }))

    setValidInput((prevState) => ({
      ...prevState,
      start_from: value.full_address.length > 0 ? true : false,
    })) 
  }

  const handleToInputChange = (value: Address) => {
    setRouteDetails((prevState) => ({
      ...prevState,
      end_to: value,
    }))

    setValidInput((prevState) => ({
      ...prevState,
      end_to: value.full_address.length > 0 ? true : false,
    })) 
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    setRouteDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))

    setValidInput((prevState) => ({
      ...prevState,
      [name]: value.length > 0 ? true : false,
    })) 
  }

  const handleBufferChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);

    setRouteDetails((prevState) => ({
      ...prevState,
      buffer_distance_miles: value,
    }))

    setValidInput((prevState) => ({
      ...prevState,
      buffer_distance_miles: value > 0 ? true : false,
    })) 
  }

  return (<>
    <div className="driver_route_create__section">
      <div className="driver_route_create__heading">
        <h2 className="driver_route_create__title">Create a Route</h2>
        <h3 className="driver_route_create__hint">Please provide your Route details and we will search Orders for you</h3>
      </div>
      <div className="driver_route_create__content">
        <div className="driver_route_create__address_wrapper">
          <div className="driver_route_create__address_block">
            <div className="driver_route_create__address_label">
                From:
            </div>
            <AddressAutocomplete
              placeholder="Enter route start location"
              onChange={handleFromInputChange}
              style={addressConfig}
              className="user_order_create__address_autocomplete"
              defaultInputValue={""}
            />
          </div>
          <div className="driver_route_create__address_block">
            <div className="driver_route_create__address_label">
                To:
            </div>
            <AddressAutocomplete
              placeholder="Enter route end location"
              onChange={handleToInputChange}
              style={addressConfig}
              className="user_order_create__address_autocomplete"
              defaultInputValue={""}
            />
          </div>
        </div>
        <div className="driver_route_create__date_time_wrapper">
          <div className="driver_route_create__date_time_start">
            <label className="driver_route_create__date_time_start_date_label">
              Enter Route Start Date:
              <input
                className="driver_route_create__date_time_start_date_input"
                type="date"
                name="start_at_date"
                onChange={(event) => handleInputChange(event)}
                value={routeDetails.start_at_date}
                required
              />
            </label>
            <label className="driver_route_create__date_time_start_time_label">
              Enter Route Start Time:
              <input
                className="driver_route_create__date_time_start_time_input"
                type="time"
                name="start_at_time"
                onChange={(event) => handleInputChange(event)}
                value={routeDetails.start_at_time}
                required
              />
            </label>
          </div>
          <div className="driver_route_create__date_time_end">
            <label className="driver_route_create__date_time_end_date_label">
              Enter Route End Date:
              <input
                className="driver_route_create__date_time_end_date_input"
                type="date"
                name="end_at_date"
                onChange={(event) => handleInputChange(event)}
                value={routeDetails.end_at_date}
                required
              />
            </label>
            <label className="driver_route_create__date_time_end_time_label">
              Enter Route End Time:
              <input
                className="driver_route_create__date_time_end_time_input"
                type="time"
                name="end_at_time"
                onChange={(event) => handleInputChange(event)}
                value={routeDetails.end_at_time}
                required
              />
            </label>
          </div> 
        </div>
        <div className="driver_route_create__buffer_wrapper">
          <label className="driver_route_create__buffer_label">
            Buffer Zone, miles:
            <input
              className="driver_route_create__buffer_input"
              placeholder="Enter buffer zone"
              type="input"
              name="buffer_distance_miles"
              onChange={(event) => handleBufferChange(event)}
              value={routeDetails.buffer_distance_miles}
            />
          </label>
          <div className="driver_route_create__buffer_info">
            <span
              className="driver_route_create__buffer_info_icon"
              aria-label="Info"
            />
              <span className="driver_route_create__buffer_info_tooltip">
                This defines the area extending outward from the route
              </span>
          </div>
        </div>
        <button 
          className="driver_route_create__submit"
          onClick={handleSubmit}
          disabled={!validateSubmitRequest()}
        >
          {isUploading ? (
            <motion.span
              animate={{ opacity: [1, 0.3, 1] }}
              transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
            >
              Creating your route...
            </motion.span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  </>)
}
