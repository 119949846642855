import "./Footer.scss"

const Socials = [
  {
    name: "instagram",
    url: "https://www.instagram.com/iujex_delivery/"
  },
  {
    name: "tiktok",
    url: "https://www.tiktok.com/@iujex?is_from_webapp=1&sender_device=pc"
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/@Your_Simple_Way_to_Ship/featured"
  },
  {
    name: "facebook",
    url: "https://www.facebook.com/profile.php?id=61551370085932"
  },
]

export default function Footer() {
  const buildSocialItems = () => {
    return Socials.map((social, index) => {
      return (
        <li key={index} className="footer__socials_item">
          <a href={social.url} className={`footer__socials_${social.name} footer__socials_social`} aria-label={social.name} target="_blank" rel="noreferrer"/>
        </li>
      )
    })
  }

  return <>
    <footer className="footer">
      <p className="footer__copyright">Copyright © 2025 iUjex. All Rights Reserved.</p>
      <ul className="footer__socials">
        {buildSocialItems()}
      </ul>
      <div className="footer__documents">
        <a href="https://iujex.com" className="footer__documents_privacy">Privacy Policy</a>
        <a href="https://iujex.com" className="footer__documents_agreement">Consumer Agreement</a>
      </div>
    </footer>
  </>
}
