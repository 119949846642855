import "./Layout.scss"
import React, { createContext, ReactNode, useContext, useEffect } from "react"
import { User } from "../../../models/user/User"
import { getMe, meUpdate } from "../../../api/user/user_api"
import { recordError } from "../../../api/error/error_api"
import { useDebugState } from "use-named-state"
import Header2 from "../../header/Header2/Header2";
import { storeToken } from "../../../utils/redirect_utils";
import Cookies from "js-cookie";
import Footer from "../../../atoms/Footer/Footer"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../redux/store"
import { setUserId, updateStoredUser } from "../../../redux/slices/userSlice"

interface UserContextData {
  user?: User
  mutateUser?: () => void
}

export const UserContext = createContext<UserContextData>({})

export function useUser() {
  return useContext(UserContext)
}

interface Props {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  const [user, setUser] = useDebugState<User | undefined>("user", undefined)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    console.log("Layout.useEffect[].updateUser")
    updateUser()
    const handleErrors = (event: any) => {
      console.error("Caught an error:", JSON.stringify(event))
      recordError({
        user_id: user?.user_id, error_message: JSON.stringify(event), path: ""
      } as WebError)
    }
    window.addEventListener("error", handleErrors)
    return () => {
      window.removeEventListener("error", handleErrors)
    }
  }, [])

  function updateUser() {
    const token = Cookies.get("jwt_token")
    if (token) {
      console.log("Layout.useEffect[].updateUser.localStorage.setItem token")
      localStorage.setItem("token", token)
    } else {
      console.log("Layout.useEffect[].updateUser.localStorage skipping")
    }

    getMe()
      .then(u => {
        console.log("Layout.useEffect[].updateUser.getMe")
        if (!u.timezone) {
          meUpdate({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
        }
        setUser(u)
      })
      .catch(e => {
        console.error(`Layout.useEffect[].updateUser.getMe: ${e}`)
        setUser(undefined)
      })
  }

  useEffect(() => {
    if (user) {
      console.log("Layout.useEffect[user]")
      dispatch(updateStoredUser(user))
    }
  }, [user])

  return <>
    <UserContext.Provider value={{ user: user, mutateUser: updateUser } as UserContextData}>
      <Header2/>
      <main className="main">{children}</main>
      <Footer />
    </UserContext.Provider>
  </>
}
