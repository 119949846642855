import "./OrderCreate_03_DeliveryDetails.scss"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { PageStage, PageStep } from "../../PageState"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import {
  setDeliveryPhoneNumber,
  setDeliveryAtInput,
  setPickupPhoneNumber,
  setPickupAtInput, cleanPickupAtInput, cleanDeliveryAtInput, cleanPickupPhoneNumber, cleanDeliveryPhoneNumber
} from "../../../../../../redux/slices/orderSlice"
import { setActivePhotos, setStep } from "../../../../../../redux/slices/stepSlice"
import { motion } from "framer-motion"
import { useMediaQuery } from 'react-responsive'
import { Breakpoint } from "../../../../../../config/breakpoints"
import { formatISOToDateAndTime, formatToISOString } from "../../../../../../utils/time_utils";


const CheckboxType = {
  pickupAsap: "pickupAsap",
  pickupContactless: "pickupContactless",
  deliveryAsap: "deliveryAsap",
  deliveryContactless: "deliveryContactless",
} as const

const InputType = {
  pickupDate: "pickupDate",
  pickupTime: "pickupTime",
  pickupPhoneNumber: "pickupPhoneNumber",
  deliveryDate: "deliveryDate",
  deliveryTime: "deliveryTime",
  deliveryPhoneNumber: "deliveryPhoneNumber",
}

export default function OrderCreate_03_DeliveryDetails() {
  const currentStage = useSelector((state: RootState) => state.step.currentStage)
  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const rate = useSelector((state: RootState) => state.order.user_rate)
  const isActivePickupAndDeliveryDetails = useSelector((state: RootState) => state.step.isActivePickupAndDeliveryDetails)
  const dispatch = useDispatch<AppDispatch>()

  const storedPickupAtInput = useSelector((state: RootState) => state.order.pickup_at_input)
  const storedPickupPhone = useSelector((state: RootState) => state.order.pickup_phone_number)

  const storedDeliveryAtInput = useSelector((state: RootState) => state.order.delivery_at_input)
  const storedDeliveryPhone = useSelector((state: RootState) => state.order.delivery_phone_number)

  const stepRef = useRef<HTMLDivElement>(null)
  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })

  const [pickupDeliveryDetails, setPickupDeliveryDetails] = useState({
    pickupAsap: true,
    pickupDate: "",
    pickupTime: "",

    pickupContactless: true,
    pickupPhoneNumber: "",

    deliveryAsap: true,
    deliveryDate: "",
    deliveryTime: "",

    deliveryContactless: true,
    deliveryPhoneNumber: "",
  })

  useEffect(() => {
    if (storedPickupAtInput) {
      const { date, time } = formatISOToDateAndTime(storedPickupAtInput)
      setPickupDeliveryDetails((prevState) => ({
        ...prevState,
        pickupDate: date,
        pickupTime: time,
        pickupAsap: false
      }))
    }
  }, [storedPickupAtInput])

  useEffect(() => {
    if (storedDeliveryAtInput) {
      const { date, time }= formatISOToDateAndTime(storedDeliveryAtInput)
      setPickupDeliveryDetails((prevState) => ({
        ...prevState,
        deliveryDate: date,
        deliveryTime: time,
        deliveryAsap: false
      }))
    }
  }, [storedDeliveryAtInput])

  useEffect(() => {
    if (storedPickupPhone) {
      setPickupDeliveryDetails((prevState) => ({
        ...prevState,
        pickupPhoneNumber: storedPickupPhone,
        pickupContactless: false,
      }))
    }
  }, [storedPickupPhone])

  useEffect(() => {
    if (storedDeliveryPhone) {
      setPickupDeliveryDetails((prevState) => ({
        ...prevState,
        deliveryPhoneNumber: storedDeliveryPhone,
        deliveryContactless: false,
      }))
    }
  }, [storedDeliveryPhone])


  const resetInput = (checkbox: keyof typeof CheckboxType) => {
    switch (checkbox) {
      case CheckboxType.pickupAsap:
        setPickupDeliveryDetails((prevState) => ({
          ...prevState,
          pickupDate: "",
          pickupTime: "",
        }))
        break
      case CheckboxType.pickupContactless:
        setPickupDeliveryDetails((prevState) => ({
          ...prevState,
          pickupPhoneNumber: "",
        }))
        break
      case CheckboxType.deliveryAsap:
        setPickupDeliveryDetails((prevState) => ({
          ...prevState,
          deliveryDate: "",
          deliveryTime: "",
        }))
        break
      case CheckboxType.deliveryContactless:
        setPickupDeliveryDetails((prevState) => ({
          ...prevState,
          deliveryPhoneNumber: "",
        }))
        break
      default:
        break
    }
  }

  const handleCheckboxChange = (checkbox: keyof typeof CheckboxType) => {
    if (!pickupDeliveryDetails[checkbox]) {
      resetInput(checkbox)
    }
    setPickupDeliveryDetails((prevState) => ({
      ...prevState,
      [checkbox]: !prevState[checkbox],
    }))

    if (currentStep !== PageStep.PickupAndDeliveryDetails) {
      switch (checkbox) {
        case CheckboxType.pickupAsap:
          dispatch(cleanPickupAtInput())
          break
        case CheckboxType.deliveryAsap:
          dispatch(cleanDeliveryAtInput())
          break

        case CheckboxType.pickupContactless:
          dispatch(cleanPickupPhoneNumber())
          break
        case CheckboxType.deliveryContactless:
          dispatch(cleanDeliveryPhoneNumber())
          break
        default:
          break
      }
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    setPickupDeliveryDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleInputBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (currentStage === PageStage.Edit || currentStep !== PageStep.PickupAndDeliveryDetails) {
      switch (name) {
        case InputType.pickupDate:
        case InputType.pickupTime:
          dispatch(setPickupAtInput(formatToISOString(`${pickupDeliveryDetails.pickupDate} ${pickupDeliveryDetails.pickupTime}`)))
          break

        case InputType.deliveryDate:
        case InputType.deliveryTime:
          dispatch(setDeliveryAtInput(formatToISOString(`${pickupDeliveryDetails.deliveryDate} ${pickupDeliveryDetails.deliveryTime}`)))
          break

        case InputType.pickupPhoneNumber:
          dispatch(setPickupPhoneNumber(pickupDeliveryDetails.pickupPhoneNumber))
          break
        case InputType.deliveryPhoneNumber:
          dispatch(setDeliveryPhoneNumber(pickupDeliveryDetails.deliveryPhoneNumber))
          break

        default:
          break
      }
    }
  }

  const handleProceedButton = () => {
    dispatch(setPickupAtInput(formatToISOString(`${pickupDeliveryDetails.pickupDate} ${pickupDeliveryDetails.pickupTime}`)))
    dispatch(setPickupPhoneNumber(pickupDeliveryDetails.pickupPhoneNumber))
    dispatch(setDeliveryAtInput(formatToISOString(`${pickupDeliveryDetails.deliveryDate} ${pickupDeliveryDetails.deliveryTime}`)))
    dispatch(setDeliveryPhoneNumber(pickupDeliveryDetails.deliveryPhoneNumber))

    dispatch(setStep(PageStep.Photos))
    dispatch(setActivePhotos(true))
  };

  useEffect(() => {
    if (currentStep === PageStep.PickupAndDeliveryDetails && stepRef.current) {
      const offsetTop = stepRef.current?.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 200,
        behavior: "smooth",
      })
    }
  }, [currentStep])

  return (isActivePickupAndDeliveryDetails &&
      <div className="user_order_create__delivery_details__section" ref={stepRef}>
        <h3 className="user_order_create__delivery_details__title">Delivery preferences</h3>
        <div className="user_order_create__delivery_details__pickup">
          <div className="user_order_create__delivery_details__pickup_accordion_header">
            <h4
              className={`user_order_create__delivery_details__pickup_title ${
                (!pickupDeliveryDetails.pickupAsap || !pickupDeliveryDetails.pickupContactless)
                  ? "user_order_create__delivery_details__pickup_title-open"
                  : "user_order_create__delivery_details__pickup_title-close"
              }`
              }
            >
              Pick Up
            </h4>
            <div className="user_order_create__delivery_details__pickup_asap">
              <input
                className="user_order_create__delivery_details__pickup_asap_input"
                type="checkbox"
                name="pickupAsap"
                value="pickupAsap"
                id="pickupAsap"
                checked={pickupDeliveryDetails.pickupAsap}
                onBlur={(event) => handleInputBlur(event)}
                onChange={() => handleCheckboxChange(CheckboxType.pickupAsap)}
              />
              <label
                className="user_order_create__delivery_details__pickup_asap_label"
                htmlFor="pickupAsap"
              >
                {isDesktopScreen ? "As Soon As Possible" : "ASAP"}
              </label>
              <div className="user_order_create__delivery_details__pickup_asap_info">
                <span
                  className="user_order_create__delivery_details__pickup_asap_info_icon"
                  aria-label="Info"
                />
                  <span className="user_order_create__delivery_details__pickup_asap_info_tooltip">
                  The pickup date will be provided after you complete the shipment order.
                </span>
              </div>
            </div>
            <div className="user_order_create__delivery_details__pickup_contactless">
              <input
                className="user_order_create__delivery_details__pickup_contactless_input"
                type="checkbox"
                name="pickupContactless"
                value="pickupContactless"
                id="pickupContactless"
                checked={pickupDeliveryDetails.pickupContactless}
                onBlur={(event) => handleInputBlur(event)}
                onChange={() => handleCheckboxChange(CheckboxType.pickupContactless)}
              />
              <label
                className="user_order_create__delivery_details__pickup_contactless_label"
                htmlFor="pickupContactless"
              >
                Contactless
                <div className="user_order_create__delivery_details__pickup_contactless_info">
                  <span
                    className="user_order_create__delivery_details__pickup_contactless_info_icon"
                    aria-label="Info"
                  />
                  <span className="user_order_create__delivery_details__pickup_contactless_info_tooltip">
                    You won’t be disturbed during the pickup. Simply leave the parcel outside for collection.
                  </span>
                </div>
              </label>

            </div>
          </div>
          <div
            className={`user_order_create__delivery_details__pickup_accordion-body ${
              (!pickupDeliveryDetails.pickupAsap || !pickupDeliveryDetails.pickupContactless)
                ? "user_order_create__delivery_details__pickup_accordion-body-open"
                : "user_order_create__delivery_details__pickup_accordion-body-close"
            }`
            }
          >
            <div className={`user_order_create__delivery_details__pickup_schedule-wrapper 
                    ${!pickupDeliveryDetails.pickupAsap
                    ? "user_order_create__delivery_details__pickup_schedule-wrapper-visible"
                    : "user_order_create__delivery_details__pickup_schedule-wrapper-hidden"
                    }`
                  }
            >
              <div className={`user_order_create__delivery_details__pickup_schedule 
                      ${!pickupDeliveryDetails.pickupAsap
                      ? "user_order_create__delivery_details__pickup_schedule-visible"
                      : "user_order_create__delivery_details__pickup_schedule-hidden"
                      }`
                    }
              >
                <label className="user_order_create__delivery_details__pickup_date_label">
                  Enter Pick Up Date1
                  <input
                    className="user_order_create__delivery_details__pickup_date_input"
                    type="date"
                    name="pickupDate"
                    onChange={(event) => handleInputChange(event)}
                    onBlur={(event) => handleInputBlur(event)}
                    value={pickupDeliveryDetails.pickupDate}
                  />
                </label>
                <label className="user_order_create__delivery_details__pickup_time_label">
                  Enter Pick Up Time
                  <input
                    className="user_order_create__delivery_details__pickup_time_input"
                    type="time"
                    name="pickupTime"
                    onChange={(event) => handleInputChange(event)}
                    onBlur={(event) => handleInputBlur(event)}
                    value={pickupDeliveryDetails.pickupTime}
                  />
                </label>
              </div>
            </div>
            <div className={`user_order_create__delivery_details__pickup_phone-wrapper 
                          ${!pickupDeliveryDetails.pickupContactless
                          ? "user_order_create__delivery_details__pickup_phone-wrapper-visible"
                          : "user_order_create__delivery_details__pickup_phone-wrapper-hidden"
                          }`
                        }
            >
              <div className={`user_order_create__delivery_details__pickup_phone 
                            ${!pickupDeliveryDetails.pickupContactless
                            ? "user_order_create__delivery_details__pickup_phone-visible"
                            : "user_order_create__delivery_details__pickup_phone-hidden"}`
                          }
              >
                <label className="user_order_create__delivery_details__pickup_phone_label">
                  Enter Pickup Phone
                  <input
                    className="user_order_create__delivery_details__pickup_phone_input"
                    type="tel"
                    name="pickupPhoneNumber"
                    onChange={(event) => handleInputChange(event)}
                    onBlur={(event) => handleInputBlur(event)}
                    value={!pickupDeliveryDetails.pickupPhoneNumber ? storedPickupPhone : pickupDeliveryDetails.pickupPhoneNumber}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="user_order_create__delivery_details__delivery">
          <div className="user_order_create__delivery_details__delivery_accordion_header">
            <h4
              className={`user_order_create__delivery_details__delivery_title ${
                (!pickupDeliveryDetails.deliveryAsap || !pickupDeliveryDetails.deliveryContactless)
                  ? "user_order_create__delivery_details__delivery_title-open"
                  : "user_order_create__delivery_details__delivery_title-close"
              }`}
            >
              Delivery
            </h4>
            <div className="user_order_create__delivery_details__delivery_asap">
              <input
                className="user_order_create__delivery_details__delivery_asap_input"
                type="checkbox"
                name="deliveryAsap"
                value="deliveryAsap"
                id="deliveryAsap"
                checked={pickupDeliveryDetails.deliveryAsap}
                onBlur={(event) => handleInputBlur(event)}
                onChange={() => handleCheckboxChange(CheckboxType.deliveryAsap)}
              />
              <label
                className="user_order_create__delivery_details__delivery_asap_label"
                htmlFor="deliveryAsap"
              >
                {isDesktopScreen ? "As Soon As Possible" : "ASAP"}
              </label>
              <div className="user_order_create__delivery_details__delivery_asap_info">
                <span
                  className="user_order_create__delivery_details__delivery_asap_info_icon"
                  aria-label="Info"
                />
                  <span className="user_order_create__delivery_details__delivery_asap_info_tooltip">
                  The delivery date will be provided after you complete the shipment order.
                </span>
              </div>
            </div>
            <div className="user_order_create__delivery_details__delivery_contactless">
              <input
                className="user_order_create__delivery_details__delivery_contactless_input"
                type="checkbox"
                name="deliveryContactless"
                value="deliveryContactless"
                id="deliveryContactless"
                checked={pickupDeliveryDetails.deliveryContactless}
                onBlur={(event) => handleInputBlur(event)}
                onChange={() => handleCheckboxChange(CheckboxType.deliveryContactless)}
              />
              <label
                className="user_order_create__delivery_details__delivery_contactless_label"
                htmlFor="deliveryContactless"
              >
                Contactless
              </label>
              <div className="user_order_create__delivery_details__delivery_contactless_info">
                <span
                  className="user_order_create__delivery_details__delivery_contactless_info_icon"
                  aria-label="Info"
                />
                  <span className="user_order_create__delivery_details__delivery_contactless_info_tooltip">
                  The recipient won’t be disturbed during delivery. The package will be left outside.
                </span>
              </div>
            </div>
          </div>
          <div
            className={`user_order_create__delivery_details__delivery_accordion-body ${
              (!pickupDeliveryDetails.deliveryAsap || !pickupDeliveryDetails.deliveryContactless)
                ? "user_order_create__delivery_details__delivery_accordion-body-open"
                : "user_order_create__delivery_details__delivery_accordion-body-close"
            }`}
          >
            <div className={`user_order_create__delivery_details__delivery_schedule-wrapper 
                  ${!pickupDeliveryDetails.deliveryAsap
                  ? "user_order_create__delivery_details__delivery_schedule-wrapper-visible"
                  : "user_order_create__delivery_details__delivery_schedule-wrapper-hidden"
                }`}
            >
              <div className={`user_order_create__delivery_details__delivery_schedule 
                    ${!pickupDeliveryDetails.deliveryAsap
                    ? "user_order_create__delivery_details__delivery_schedule-visible"
                    : "user_order_create__delivery_details__delivery_schedule-hidden"
                  }`}
              >
                <label className="user_order_create__delivery_details__delivery_date_label">
                  Enter Delivery Date
                  <input
                    className="user_order_create__delivery_details__delivery_date_input"
                    type="date"
                    name="deliveryDate"
                    onChange={(event) => handleInputChange(event)}
                    onBlur={(event) => handleInputBlur(event)}
                    value={pickupDeliveryDetails.deliveryDate}
                  />
                </label>
                <label className="user_order_create__delivery_details__delivery_time_label">
                  Enter Delivery Time
                  <input
                      className="user_order_create__delivery_details__delivery_time_input"
                      type="time"
                      name="deliveryTime"
                      onChange={(event) => handleInputChange(event)}
                      onBlur={(event) => handleInputBlur(event)}
                      value={pickupDeliveryDetails.deliveryTime}
                  />
                </label>
              </div>
            </div>
            <div className={`user_order_create__delivery_details__delivery_phone-wrapper
                        ${!pickupDeliveryDetails.deliveryContactless
                        ? "user_order_create__delivery_details__delivery_phone-wrapper-visible"
                        : "user_order_create__delivery_details__delivery_phone-wrapper-hidden"
                      }`}
            >
              <div className={`user_order_create__delivery_details__delivery_phone 
                            ${!pickupDeliveryDetails.deliveryContactless
                            ? "user_order_create__delivery_details__delivery_phone-visible"
                            : "user_order_create__delivery_details__delivery_phone-hidden"}`}
              >
                <label className="user_order_create__delivery_details__delivery_phone_label">
                  Enter Delivery Phone
                  <input
                    className="user_order_create__delivery_details__delivery_phone_input"
                    type="tel"
                    name="deliveryPhoneNumber"
                    onChange={(event) => handleInputChange(event)}
                    onBlur={(event) => handleInputBlur(event)}
                    value={pickupDeliveryDetails.deliveryPhoneNumber}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      {currentStep === PageStep.PickupAndDeliveryDetails &&
        <div className="user_order_create__rate_section">
          <div className="user_order_create__rate_wrapper">
            <div className="user_order_create__rate_title">
              Your Rate:
            </div>
              {rate > 0 &&
                  <motion.div
                    className="user_order_create__rate_amount"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                  >
                    {rate} $
                  </motion.div>
              }
          </div>
          <button
            onClick={handleProceedButton}
            className="user_order_create__rate_button"
          >
            Set Up Pickup And Delivery Details
          </button>
        </div>
      }
    </div>
  )
}
