import './style.css'
import TextField_ from '../../../../atoms/TextField/TextField'
import OrderTimeline from '../../OrderTimeline/OrderTimeline_old'
import { packagesToString } from '../../../../utils/package_utils'
import Divider_ from '../../../../atoms/Divider/Divider'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import Text_ from "../../../../atoms/Text/Text"
import { useOrderTrack } from "../../../../api/order/unauthorized_order_api";

interface Props {
  orderId: string
}

export default function OrderTrackCard({ orderId }: Props) {
  const { data: order } = useOrderTrack(orderId)
  return <div className='order_track_card'>
    {order ? <div className='order_track_card__fields'>
      <Divider_ text='Timeline' className='order_track_card__divider__timeline'/>
      <OrderTimeline orderStatus={order.status}/>

      <Divider_ text='Short info' className='order_track_card__divider__short_info'/>
      <TextField_ label='Pickup from' value={order.pickup_from_short.short_address} className='order_track_card__pickup_from_short'/>
      <TextField_ label='Pickup at' value={userTimeZoneDate(order.pickup_at_input)} className='order_track_card__pickup_at_input'/>
      <TextField_ label='Delivery to' value={order.delivery_to_short.short_address} className='order_track_card__delivery_to_short'/>
      <TextField_ label='Delivery at' value={userTimeZoneDate(order.delivery_at_input)} className='order_track_card__delivery_at_input'/>
      <TextField_ label='Packages' value={packagesToString(order.packages)} className='order_track_card__packages'/>
      <TextField_ label='Order ID' value={order.order_id} className='order_track_card__order_id'/>
    </div> : <>
      <Text_ content={`Order '${orderId}' not found`}/>
    </>}
  </div>
}

