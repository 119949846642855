import "./OrderCreate_02_Package.scss"
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import { PageStage, PageStep } from "../../PageState"
import { DIMENSIONS, OrderPackageItem, OrderComponentStatus, PACKAGES, PackageIconType, PACKAGE_DIMENSIONS} from "../../../../../../models/Package"
import { deletePackage, setPackages } from "../../../../../../redux/slices/orderSlice"
import { setActivePickupAndDeliveryDetails, setStep } from "../../../../../../redux/slices/stepSlice"
import { nanoid } from 'nanoid'
import { useMediaQuery } from 'react-responsive';
import { Breakpoint } from "../../../../../../config/breakpoints";

export default function OrderCreate_02_Package() {

  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const currentStage = useSelector((state: RootState) => state.step.currentStage)
  const isActivePackages = useSelector((state: RootState) => state.step.isActivePackages)
  const pickupFrom = useSelector((state: RootState) => state.order.pickup_from?.full_address)
  const deliverTo = useSelector((state: RootState) => state.order.delivery_to?.full_address)
  const packages = useSelector((state: RootState) => state.order.packages)
  const dispatch = useDispatch<AppDispatch>()
  const stepRef = useRef<HTMLDivElement>(null)
  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })


  const initialPackages = useMemo<OrderPackageItem[]>(() => {
    if (packages?.length) {
      return packages.map(pkg => ({
        id: nanoid(),
        length_in: pkg.length_in ?? 0,
        width_in: pkg.width_in ?? 0,
        height_in: pkg.height_in ?? 0,
        weight_lb: pkg.weight_lb ?? 0,
        status: OrderComponentStatus.add,
        isAccordionOpen: true,
        selectedPackageIconId: undefined,
      }));
    }
    return [{
      id: nanoid(),
      length_in: 0,
      width_in: 0,
      height_in: 0,
      weight_lb: 0,
      status: OrderComponentStatus.add,
      isAccordionOpen: false,
      selectedPackageIconId: undefined,
    }];
  }, [packages]);

  const [packagesDimensions, setPackagesDimension] = useState<OrderPackageItem[]>(initialPackages)

  const checkIfFieldsValid = () => {
    if (!pickupFrom || !deliverTo) return false

    return !packagesDimensions.some(
      (packageDimensions) =>
        packageDimensions.length_in === 0 ||
        packageDimensions.width_in === 0 ||
        packageDimensions.height_in === 0
    )
  }

  const handleAccordionClick = (id: string) => {
    setPackagesDimension((prevPackages) =>
      prevPackages.map((prevPackage) =>
        prevPackage.id === id ? { ...prevPackage, isAccordionOpen: !prevPackage.isAccordionOpen } : prevPackage
      )
    )
  }

  const handlePackageIconClick = (packageIconType: PackageIconType, packageItem: OrderPackageItem) => {
    let updatedPackages: OrderPackageItem[] = [];
    if (packageItem.selectedPackageIconId !== packageIconType.id) {
      setPackagesDimension((prevPackages) => {
        updatedPackages = prevPackages.map((prevPackage) =>
          prevPackage.id === packageItem.id
            ? {
              ...prevPackage,
              length_in: packageIconType.length_in,
              width_in: packageIconType.width_in,
              height_in: packageIconType.height_in,
              isAccordionOpen: true,
              selectedPackageIconId: packageIconType.id
              }
            : prevPackage
        );
        return updatedPackages
      });
    } else {
      setPackagesDimension((prevPackages) => {
        updatedPackages = prevPackages.map((prevPackage) =>
          prevPackage.id === packageItem.id
            ? {
              ...prevPackage,
              length_in: 0,
              width_in: 0,
              height_in: 0,
              selectedPackageIconId: undefined,
            }
            : prevPackage
        )
        return updatedPackages
      })
    }
    setTimeout(() => {
      if (currentStep !== PageStep.Packages || currentStage === PageStage.Edit) {
        dispatch(setPackages(updatedPackages));
      }
    }, 0)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const name = event.target.name as keyof typeof packagesDimensions
    const value = Number(event.target.value)

    setPackagesDimension((prevPackages) =>
      prevPackages.map((prevPackage) =>
        prevPackage.id === id ? { ...prevPackage, [name]: value } : prevPackage
      )
    )
  }

  const handleInputBlur = () => {
    if (currentStep !== PageStep.Packages || currentStage === PageStage.Edit ) {
      dispatch(setPackages(packagesDimensions))
    }
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, id: string) => {
    const value = Number(event.target.value)

    setPackagesDimension((prevPackages) =>
      prevPackages.map((prevPackage) =>
        prevPackage.id === id ? { ...prevPackage, weight_lb: value } : prevPackage
      )
    )

    if (currentStage === PageStage.Edit) {
      dispatch(setPackages(packagesDimensions))
    }
  }

  const handleShowRatesButtonClick = () => {
    dispatch(setPackages(packagesDimensions))

    dispatch(setStep(PageStep.PickupAndDeliveryDetails))
    dispatch(setActivePickupAndDeliveryDetails(true))
  }

  const handleAddPackageClick = () => {
    setPackagesDimension((prevPackages) =>
      prevPackages.map((prevPackage) => ({ ...prevPackage, status: OrderComponentStatus.summary }))
    )
    setPackagesDimension([
      ...packagesDimensions,
      {
        id: nanoid(),
        length_in: 0,
        width_in: 0,
        height_in: 0,
        weight_lb: 0,
        status: OrderComponentStatus.add,
        isAccordionOpen: false,
        selectedPackageIconId: undefined
      }
    ])
    dispatch(setStep(PageStep.Packages))
  }

  const handleDeletePackage = (packageItem: OrderPackageItem) => {
    setPackagesDimension((prevPackages) => prevPackages.filter((prevPackage) => prevPackage.id !== packageItem.id))
    dispatch(deletePackage(packageItem))
    if (currentStage === PageStage.Edit) {
      dispatch(setPackages(packagesDimensions))
    }
  }

  function createPackageCardClass(packageIconId: number, packageItem: OrderPackageItem) {
    return `user_order_create__packages__icons__wrapper ${
      packageItem.selectedPackageIconId === packageIconId
        ? "user_order_create__packages__icons__wrapper-selected"
        : ""
    }`.trim()
  }

  function buildPackageIconCard(packageIconType: PackageIconType, packageItem: OrderPackageItem) {
    return <>
      <div 
        className={createPackageCardClass(packageIconType.id, packageItem)} 
        key={packageIconType.id} 
        onClick={() => handlePackageIconClick(packageIconType, packageItem)}
      >
        <div className="user_order_create__packages__icons__icon">
          <img src={isDesktopScreen ? packageIconType.image_desktop : packageIconType.image_mobile} alt={packageIconType.label}/>
        </div>
        <div className="user_order_create__packages__icons__title_and_description">
          <div className="user_order_create__packages__icons__title">{packageIconType.label}</div>
          <div className="user_order_create__packages__icons__description">{`${packageIconType.length_in} x ${packageIconType.width_in} x ${packageIconType.height_in} ${packageIconType.unit}`}</div>
        </div>
      </div>
    </>
  }

  function buildPackageIconsSection(packageItem: OrderPackageItem) {
    return Object.values(PACKAGES).map((packageIconType) => {
      return buildPackageIconCard(packageIconType, packageItem)
    })
  }

  function buildDimensionColumn(dimensionLabel: string, dimensionName: string, packageItem: OrderPackageItem) {
    return <>
      <div className="user_order_create__packages__table__element" key={dimensionName}>
        <label
          className="user_order_create__packages__table__element__header"
          htmlFor={dimensionName}>
          {dimensionLabel}
        </label>
        <input
          className="user_order_create__packages__table__element__input"
          name={dimensionName}
          id={dimensionName}
          value={packageItem[dimensionName as keyof Pick<OrderPackageItem, "length_in" | "width_in" | "height_in">]}
          onChange={(event) => handleInputChange(event, packageItem.id)}
          onBlur={handleInputBlur}
        />
      </div>
    </>
  }

  function buildDimensionsTable(packageItem: OrderPackageItem) {
    return ( 
      <>
        { 
          PACKAGE_DIMENSIONS.map((dimension) => {
            return buildDimensionColumn(dimension.label, dimension.name, packageItem)
          })
        }
        <div className="user_order_create__packages__table__weight">
          <label className="user_order_create__packages__table__weight_label">Weight, lb*</label>
          <select
            name="weight"
            id="weight"
            className="user_order_create__packages__table__weight-select"
            value={packageItem.weight_lb}
            onChange={(event) => handleSelectChange(event, packageItem.id)}
          >
            <option value={50} selected>under 50</option>
            <option value={200}>51-200</option>
            <option value={3600}>201-3,600</option>
          </select>
        </div>
      </>
    )
  }

  function createDimensionsTitle(packageItem: OrderPackageItem) {
    return packageItem.selectedPackageIconId ? "Selected parcel dimensions" : "Didn’t find the right parcel size?"
  }

  function createDimensionsDescription(packageItem: OrderPackageItem) {
    return packageItem.selectedPackageIconId ? "Check your parcel’s size and enter the weight" : "Enter your custom dimensions"
  }

  function createPackagesHeading(index: number) {
    return (packagesDimensions.length <= 1) ? "Select from standard parcel sizes" : `Package ${index + 1}`
  }

  useEffect(() => {
    if (currentStep === PageStep.Packages && stepRef.current) {
      const offsetTop = stepRef.current?.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 90,
        behavior: "smooth",
      })
    }
  }, [currentStep])

  return (isActivePackages && <>
          <div className="user_order_create__packages__section" ref={stepRef}>
            {
              packagesDimensions.map((packageItem, index) => {
                return <>
                  <div key={packageItem.id} className="user_order_create__packages__heading">
                    <div className="user_order_create__packages__title">{createPackagesHeading(index)}</div>
                    {(packagesDimensions.length > 1) &&
                      <button
                        className="user_order_create__packages__delete"
                        onClick={() => handleDeletePackage(packageItem)}
                      >
                        Delete package
                      </button>
                    }
                  </div>
                  <div className="user_order_create__packages__icons">
                    {buildPackageIconsSection(packageItem)}
                  </div>
                  <div className="user_order_create__packages__table">
                    <div className={`user_order_create__packages__table__heading ${
                      packageItem.isAccordionOpen
                        ? "user_order_create__packages__table__heading-open"
                        : "user_order_create__packages__table__heading-close"
                      }`} onClick={() => handleAccordionClick(packageItem.id)}
                    >
                    <div className="user_order_create__packages__table__title">{createDimensionsTitle(packageItem)}</div>
                    <div className="user_order_create__packages__table__description">{createDimensionsDescription(packageItem)}</div>
                    </div>
                    <div
                      className={`user_order_create__packages__table__accordion ${
                        packageItem.isAccordionOpen
                          ? "user_order_create__packages__table__accordion-open"
                          : "user_order_create__packages__table__accordion-close"
                      }`}
                    >
                      <div className="user_order_create__packages__table__dimensions">
                        {buildDimensionsTable(packageItem)}
                      </div>
                    </div>
                  </div>
                </>
              })}
              <button
                  className="user_order_create__packages__add"
                  onClick={handleAddPackageClick}
              >
                  Add another package
              </button>
            { ( currentStep === PageStep.Packages && currentStage === PageStage.Fill ) &&
                <button
                    disabled={!checkIfFieldsValid()}
                    className="user_order_create__packages__show_rate"
                    onClick={handleShowRatesButtonClick}
                >Show Rate
                </button>
            }
          </div>
      </>
  )
}
