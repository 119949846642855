import useSWR, { SWRResponse } from "swr"
import { UserOrder, UserOrderTable } from "../../models/order/UserOrder"
import { http } from "../http"
import { PageResponse } from "../../models/Page"
import { useBaseOrders } from "../../utils/swr_utils"
import { OrderStatus } from "../../models/order/OrderStatus"


export function useUserOrder(orderId: string): SWRResponse<UserOrder> {
  return useSWR<UserOrder>([`/orders/${orderId}`])
}

export function useUserOrderCurrent(): SWRResponse<UserOrder | undefined> {
  return useSWR<UserOrder | undefined>(["/orders/current"])
}

export function useUserOrders(page?: number, pageSize?: number, orderStatus?: OrderStatus): SWRResponse<PageResponse<UserOrderTable>> {
  return useBaseOrders("/orders/search", page, pageSize, orderStatus)
}

export async function userOrderFileDownload(orderId: string, fileId: string): Promise<Blob | undefined> {
  return http.getFile(`/orders/${orderId}/files/${fileId}`)
}
