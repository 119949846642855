import "./OrderCreate_01_Address.scss"
import { Address } from "../../../../../../models/Address"
import AddressAutocomplete from "../../../../../../atoms/AddressAutocomplete/AddressAutocomplete"
import { PageStage, PageStep } from "../../PageState"
import { useMobile } from "../../utils"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import { setAddressFrom, setAddressTo, updateAddressFrom, updateAddressTo } from "../../../../../../redux/slices/orderSlice"
import { setActivePackages, setStep } from "../../../../../../redux/slices/stepSlice"
import { Breakpoint } from "../../../../../../config/breakpoints"
import { useMediaQuery } from 'react-responsive';

interface AddressState {
  from: Address | undefined
  to: Address | undefined
  statusFrom: ValidationStatus
  statusTo: ValidationStatus
}

enum ValidationStatus {
  new,
  incomplete,
  complete
}

export default function OrderCreate_01_Address() {

  const addressConfig = { width: useMobile() ? "100%" : "500px", margin: "auto" }
  const dispatch = useDispatch<AppDispatch>()
  const currentStage = useSelector((state: RootState) => state.step.currentStage)
  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const isActiveAddresses = useSelector((state: RootState) => state.step.isActiveAddresses)
  const pickup_from = useSelector((state: RootState) => state.order.pickup_from)
  const delivery_to = useSelector((state: RootState) => state.order.delivery_to)
  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })


  const [address, setAddress] = useState<AddressState>({
    from: pickup_from,
    to: delivery_to,
    statusFrom: ValidationStatus.new,
    statusTo: ValidationStatus.new
  })

  const checkValidationStatus = () => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      statusFrom: prevAddress.from ? ValidationStatus.complete : ValidationStatus.incomplete,
      statusTo: prevAddress.to ? ValidationStatus.complete : ValidationStatus.incomplete,
    }));
  }

  useEffect(() => {
    setAddress({
      ...address,
      from: pickup_from,
      to: delivery_to,
    } as AddressState)
  }, [pickup_from, delivery_to])

  const handleFromInputChange = (value: Address) => {
    setAddress({
      ...address,
      from: value,
      statusFrom: ValidationStatus.complete
    })
    if ((currentStage === PageStage.Fill && currentStep !== PageStep.Addresses)
      || currentStage === PageStage.Edit) {
      dispatch(updateAddressFrom(value))
    }
  }

  const handleToInputChange = (value: Address) => {
    setAddress({
      ...address,
      to: value,
      statusTo: ValidationStatus.complete
    })
    if ((currentStage === PageStage.Fill && currentStep !== PageStep.Addresses)
      || currentStage === PageStage.Edit) {
      dispatch(updateAddressTo(value))
    }
  }

  const handleDetailsButtonClick = () => {
    checkValidationStatus()
    dispatch(setAddressFrom(address.from))
    dispatch(setAddressTo(address.to))

    dispatch(setStep(PageStep.Packages))
    dispatch(setActivePackages(true))
  }

  return isActiveAddresses && <section className="user_order_create__address">
      <div className="user_order_create__address__label_wrapper">
          <div className="user_order_create__address__label">
            {isDesktopScreen
              ? "Join iUjex today — because shipping doesn’t have to cost the Earth 🌎"
              : "iUjex - because shipping doesn’t have to cost the Earth"}
          </div>
      </div>
      <div className="user_order_create__address__title_big">
          Affordable Eco-Friendly Shipping On Your Schedule
      </div>
      <div className="user_order_create__address__title_small">
          Get your rate in seconds
      </div>
      <div className="user_order_create__address__address">
          <div className="user_order_create__address__address__block">
              <div className="user_order_create__address__address__block__label">
                  From:
              </div>
              <AddressAutocomplete
                  placeholder="Enter pickup address or location"
                  onChange={handleFromInputChange}
                  style={addressConfig}
                  className={`user_order_create__address__address__block__autocomplete 
                ${address.from && `user_order_create__address__address__block__autocomplete-complete`}
                ${address.statusFrom === ValidationStatus.incomplete && `user_order_create__address__address__block__autocomplete-incomplete`}
                
                `}
                  defaultInputValue={address.from ? address.from.full_address : ""}
              />
          </div>
          <div className="user_order_create__address__address__block">
              <div className="user_order_create__address__address__block__label">
                  To:
              </div>
              <AddressAutocomplete
                  placeholder="Enter delivery address or location"
                  onChange={handleToInputChange}
                  style={addressConfig}
                  className={`user_order_create__address__address__block__autocomplete 
                ${address.to && `user_order_create__address__address__block__autocomplete-complete`}
                ${address.statusTo === ValidationStatus.incomplete && `user_order_create__address__address__block__autocomplete-incomplete`}
              `}
                  defaultInputValue={delivery_to ? delivery_to.full_address : ""}
              />
          </div>
      </div>
    {currentStage === PageStage.Fill && currentStep === PageStep.Addresses &&
        <button
            onClick={handleDetailsButtonClick}
            className="user_order_create__address__ship_details_button"
        >
            Shipment Details
        </button>
    }
  </section>
}
