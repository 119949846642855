import './AgreementAdminsCardPage.css'
import { useParams } from 'react-router-dom'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'
import AdminAgreementCard from '../../../../components/agreement/admin/AdminAgreementCard/AdminAgreementCard'

export default function AgreementAdminsCardPage() {
  const { agreementId } = useParams()
  return <div className='agreement_admins__card_page'>
    <AdminTab tabIndex={AdminTabConfig.Agreements.index}/>
    <AdminAgreementCard agreementId={agreementId as string}/>
  </div>
}
