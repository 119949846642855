import "./OrderCreate_06_Login.scss"

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import { PageStep } from "../../PageState"
import { AnimatePresence, motion } from "framer-motion"
import { setActiveLogin, setActivePayment, setStep } from "../../../../../../redux/slices/stepSlice"
import { getBackendUrl } from "../../../../../../config/env"
import { useUser } from "../../../../../layout/Layout/Layout"
import { authOtpRequest, authRequest } from "../../../../../../api/auth/auth_api"
import { AuthCodeRequest, AuthRequest } from "../../../../../../models/auth/Auth"
import Cookies from "js-cookie"


const OTP_LENGTH = 6


export default function OrderCreate_06_Login() {
  const { user, mutateUser } = useUser()
  const stepRef = useRef<HTMLDivElement>(null)
  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const isActiveLogin = useSelector((state: RootState) => state.step.isActiveLogin)
  const dispatch = useDispatch<AppDispatch>()
  const [email, setEmail] = useState("")
  const [isOtpOpen, setOtpOpen] = useState(false)
  const [otp, setOtp] = useState(Array(OTP_LENGTH).fill(""))
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const handleSendOtpClick = () => {
    authRequest({ email: email } as AuthRequest)
      .then(() => setOtpOpen(true) )
  }

  const handleBackClick = () => {
    setOtpOpen(false)
  }

  function handleSetEmail(event: any) {
    setEmail(event.target.value)
  }

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (newOtp.join("").length === OTP_LENGTH) {
      handleOtpOnComplete(newOtp.join(""))
    }

    if (value && index < (OTP_LENGTH - 1)) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  const handleOtpOnComplete = (completeOtp: string) => {
    console.log("otp: " + completeOtp)
  }

  const handleOtpPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pastedData = event.clipboardData.getData("text").trim()
    const newOtp = [...otp]

    pastedData.split("").forEach((char, index) => {
      if (index < newOtp.length) {
        newOtp[index] = char
      }

    })

    setOtp(newOtp)
  }

  const handleSignInClick = () => {
    authOtpRequest(
      {
        email: email,
        code: otp.join("")
      } as AuthCodeRequest
    ).then(response => {
      Cookies.set("jwt_token", response.token)
      mutateUser && mutateUser()
    }).then(() => {
      dispatch(setStep(PageStep.Payment))
      dispatch(setActivePayment(true))
      dispatch(setActiveLogin(false))
    })
  }

  const buildOtpCells = () => {
    return otp.map((value, index) => {
      return <input
        key={index}
        ref={(el) => (inputRefs.current[index] = el)}
        type="text"
        value={value}
        onChange={(event) => handleOtpChange(event, index)}
        onKeyDown={(event) => handleKeydown(event, index)}
        onPaste={(event) => handleOtpPaste(event)}
        maxLength={1}
        className="user_order_create__login__otp_input_cell"
      />
    })
  }

  useEffect(() => {
      if (currentStep === PageStep.Login && stepRef.current) {
        const offsetTop = stepRef.current?.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: offsetTop - 200,
          behavior: "smooth",
        })
      }
  }, [currentStep])

  function isValidEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  return (isActiveLogin &&
      <AnimatePresence mode="wait">
        <section
          key="user-login-section"
          className="user_order_create__login__section"
          ref={stepRef}
        >

            {!user &&
                <motion.div
                    key="login"
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                    className={`user_order_create__login__container ${isOtpOpen ? "slide-left" : "slide-right"}`}
                >
                    <div className="user_order_create__login__wrapper">
                        <div className="user_order_create__login__google">
                            <h5 className="user_order_create__login__title-google">
                                Sign In
                            </h5>
                            <button className="user_order_create__login__button_google"
                                    onClick={() => window.location.href = `${getBackendUrl()}/login?redirect_to=${window.location.href}`}
                            >
                                Sign In With Google
                            </button>
                        </div>
                        <p className="user_order_create__login__or">
                            or
                        </p>
                        <div className="user_order_create__login__otp">
                            <h5 className="user_order_create__login__title-otp">
                                Enter email to receive a One-Time Password
                            </h5>
                            <label className="user_order_create__login__label">
                                <input
                                    className="user_order_create__login__input"
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    onChange={handleSetEmail}
                                />
                            </label>
                            <button
                                className="user_order_create__login__button_otp"
                                disabled={!isValidEmail(email)}
                                onClick={handleSendOtpClick}
                            >
                                Request One-Time Password
                            </button>
                        </div>
                    </div>
                    <div className="user_order_create__login__otp_wrapper">
                        <button
                            className="user_order_create__login__otp_button_back"
                            onClick={handleBackClick}
                        >
                            Back
                        </button>
                        <h4 className="user_order_create__login__otp_title">Enter One-Time Password to Sign In</h4>
                        <div className="user_order_create__login__otp_input">
                          {buildOtpCells()}
                        </div>
                        <button
                            onClick={handleSignInClick}
                            className="user_order_create__login__otp_button_signin"
                        >
                            Sign In
                        </button>
                        <button className="user_order_create__login__otp_button_resend">Resend Code</button>
                    </div>
                </motion.div>
            }
        </section>
      </AnimatePresence>
  )
}
