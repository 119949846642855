import "./DriverDashboard.scss"
import DriverMap from '../../order/driver/DriverMap/DriverMap'
import DriverOrdersTable from '../../order/driver/DriverOrdersTable/DriverOrdersTable'
import { useEffect, useState } from "react"
import { useDriverOrdersAvailable } from "../../../api/order/driver_order_api"
import { useMediaQuery } from 'react-responsive'
import { Breakpoint } from "../../../config/breakpoints"
import { motion, AnimatePresence } from "framer-motion";
import Map_ from "../../../atoms/Map/Map"
import { buildMarkers, buildPolylines } from "../../../atoms/Map/utils"
import OrderTimeline from "../../order/OrderTimeline/OrderTimeline"
import OrderDetails from "../../order/OrderDetails/OrderDetails"


enum AvailableOrderTab {
  timeline,  //0
  details   //1
}

const AvailableOrderTabs = [
  {
    id: 0,
    name: "timeline",
    label: "Timeline",
  },
  {
    id: 1,
    name: "details",
    label: "Details",
  }
]

export default function DriverDashboard() {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [activeTabs, setActiveTabs] = useState<{ [key: string]: number }>({})
  const { data: availableOrdersPage } = useDriverOrdersAvailable(page, pageSize)
  const availableOrders = availableOrdersPage?.content
  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop })

  const mapStyle = 
  isDesktopScreen 
  ? {width: "638px", height: "520px"}
  : {width: "338px", height: "310px"}

  const buildAvailableOrderTabs = (orderId: string) => {
    return AvailableOrderTabs.map((tab) => {
      return (
        <button 
          className={`
            driver_dashboard__order_tab
            driver_dashboard__order_tab-${tab.name}
            ${activeTabs[orderId] === tab.id 
              ? "driver_dashboard__order_tab-active"
              : ""}`
          }
          onClick={() => handleTabClick(tab.id, orderId)}
        >
          {tab.label}
        </button>
      )
    })
  }

  const handleAssignClick = () => {
    
  }

  const handleTabClick = (tabId: number, orderId: string) => {
    setActiveTabs((prevState) => ({
      ...prevState,
      [orderId]: tabId
    }))
  }

  useEffect(() => {
    if (!availableOrders) return; 
    const initialTabs = availableOrders?.reduce((acc, order) => {
      acc[order.order_id] = AvailableOrderTab.timeline; // Default tab
      return acc;
    }, {} as { [key: string]: number });

    setActiveTabs(initialTabs);
  }, [availableOrders])

  const buildAvailableOrders = () => {
    return availableOrders && availableOrders.map((order) => {
      return (
        <div className="driver_dashboard__order_wrapper">
          <div className="driver_dashboard__heading">
            <h2 className="driver_dashboard__title">Order #{order.order_id}</h2>
            <button 
              className="driver_dashboard__button-assign" 
              onClick={handleAssignClick} 
              aria-label="actions" 
            >
              Assign Order
            </button>
          </div>
          <div className="driver_dashboard__order_content">
            <Map_  
              polylines={buildPolylines(order)}
              markers={buildMarkers([order])}
              className="driver_dashboard__order_map"
              width={mapStyle.width}
              height={mapStyle.height}
            />
            <div className="driver_dashboard__order">
              <div className="driver_dashboard__order_tabs">
                {buildAvailableOrderTabs(order.order_id)}
              </div>
              <div className="user_dashboard__current_order_wrapper">
                <AnimatePresence mode="wait">
                  { (activeTabs[order.order_id] === AvailableOrderTab.timeline) &&          
                    <motion.div 
                      key="timeline"
                      className="user_dashboard__current_order_timeline"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2, ease: "easeInOut" }}
                    >
                      <OrderTimeline order={order} />
                    </motion.div>
                  }
                  { (activeTabs[order.order_id] === AvailableOrderTab.details) &&
                    <motion.div 
                      key="details"
                      className="user_dashboard__current_order_details"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2, ease: "easeInOut" }}
                    >
                      <OrderDetails order={order} />
                    </motion.div>
                  }
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return ( 
    <div className="driver_dashboard__section">
      <ul className="driver_dashboard__orders">
        {buildAvailableOrders()}
      </ul>
    </div>
  )
}
