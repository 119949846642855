import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface Files {
	files_on_creation: File[],
	files_on_pickup: File[],
	files_on_complete: File[],
}

const initialState: Files = {
	files_on_creation: [],
	files_on_pickup: [],
	files_on_complete: [],
}

const filesSlice = createSlice({
	name: "files",
	initialState,
	reducers: {
		setCreateFiles: (state, action: PayloadAction<File[]>) => {
			if (state.files_on_creation) {
				state.files_on_creation = action.payload
			}
		},
	}
})

export const {setCreateFiles} = filesSlice.actions

export default filesSlice.reducer