export interface OrderPackage {
  id: string
  length_in?: number
  width_in?: number
  height_in?: number
  weight_lb?: number
}

export const enum OrderComponentStatus {
  add,
  edit,
  summary
}

export interface OrderPackageItem {
  id: string
  length_in?: number
  width_in?: number
  height_in?: number
  weight_lb?: number
  status: number
  isAccordionOpen: boolean
  selectedPackageIconId: number | undefined
}

export interface PackageIconType {
  id: number
  label: string
  image_desktop: string
  image_mobile: string
  length_in: number
  width_in: number
  height_in: number
  unit: string
}

export const PACKAGES: Record<number, PackageIconType> = {
  1: {
    id: 1,
    label: "Envelope",
    image_desktop: "/images/createOrder/packages/parcel_1.svg",
    image_mobile: "/images/createOrder/packages/parcel_1_mobile.svg",
    length_in: 12.5,
    width_in: 9.5,
    height_in: 1,
    unit: "in",
  },
  2: {
    id: 2,
    label: "Small Box",
    image_desktop: "/images/createOrder/packages/parcel_2.svg",
    image_mobile: "/images/createOrder/packages/parcel_2_mobile.svg",
    length_in: 12,
    width_in: 10,
    height_in: 8,
    unit: "in",
  },
  3: {
    id: 3,
    label: "Medium Box",
    image_desktop: "/images/createOrder/packages/parcel_3.svg",
    image_mobile: "/images/createOrder/packages/parcel_3_mobile.svg",
    length_in: 24,
    width_in: 18,
    height_in: 18,
    unit: "in",
  },
  4: {
    id: 4,
    label: "Large Box",
    image_desktop: "/images/createOrder/packages/parcel_4.svg",
    image_mobile: "/images/createOrder/packages/parcel_4_mobile.svg",
    length_in: 24,
    width_in: 24,
    height_in: 24,
    unit: "in",
  },
  5: {
    id: 5,
    label: "One Pallet",
    image_desktop: "/images/createOrder/packages/pallet_1.svg",
    image_mobile: "/images/createOrder/packages/pallet_1_mobile.svg",
    length_in: 48,
    width_in: 48,
    height_in: 48,
    unit: "in",
  },
  6: {
    id: 6,
    label: "Two Pallets",
    image_desktop: "/images/createOrder/packages/pallet_2.svg",
    image_mobile: "/images/createOrder/packages/pallet_2_mobile.svg",
    length_in: 96,
    width_in: 48,
    height_in: 48,
    unit: "in",
  },
  7: {
    id: 7,
    label: "Three Pallets",
    image_desktop: "/images/createOrder/packages/pallet_3.svg",
    image_mobile: "/images/createOrder/packages/pallet_3_mobile.svg",
    length_in: 144,
    width_in: 48,
    height_in: 48,
    unit: "in",
  },
  8: {
    id: 8,
    label: "Oversized",
    image_desktop: "/images/createOrder/packages/pallet_4.svg",
    image_mobile: "/images/createOrder/packages/pallet_4_mobile.svg",
    length_in: 170,
    width_in: 53,
    height_in: 70,
    unit: "in",
  },
}

export const DIMENSIONS: (keyof Pick<OrderPackageItem, "length_in" | "width_in" | "height_in">)[] = [
  "length_in",
  "width_in",
  "height_in",
]

export const PACKAGE_DIMENSIONS = [
  {
    name: "length_in",
    label: "Length, In",
  },
  {
    name: "width_in",
    label: "Width, In",
  },
  {
    name: "height_in",
    label: "Height, In",
  },
]


