import useSWR, { SWRResponse } from 'swr'
import { PageRequest, PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'
import { http } from '../http'
import {
  AdminAgreement,
  AdminAgreementCreateRequest,
  AdminAgreementFilter,
  AdminAgreementUpdateRequest
} from '../../models/agreement/AdminAgreement'

export function adminAgreementCreate(request: AdminAgreementCreateRequest, files: FileList): Promise<AdminAgreement> {
  return http.postMultipartData('/admin/agreements', request, files)
}

export function useAdminAgreement(agreementId: string): SWRResponse<AdminAgreement> {
  return useSWR<AdminAgreement>([`/admin/agreements/${agreementId}`])
}

export async function adminAgreementDownload(agreementId: string): Promise<Blob | undefined> {
  return http.getFile(`/admin/agreements/${agreementId}`)
}

export function useAdminAgreements(page?: number, pageSize?: number, filterRequest?: AdminAgreementFilter): SWRResponse<PageResponse<AdminAgreement>> {
  return usePageable(
    '/admin/agreements/search',
    {
      page: page,
      page_size: pageSize,
      filter: filterRequest,
    } as PageRequest<AdminAgreementFilter>
  )
}

export function adminAgreementSetActive(agreementId: string): Promise<AdminAgreement> {
  return http.putData<void, AdminAgreement>(`/admin/agreements/${agreementId}/active`)
}

export function adminAgreementSetInactive(agreementId: string): Promise<AdminAgreement> {
  return http.putData<void, AdminAgreement>(`/admin/agreements/${agreementId}/inactive`)
}

export function adminAgreementDelete(agreementId: string): Promise<void> {
  return http.delete(`/admin/agreements/${agreementId}`)
}

export function adminAgreementUpdate(agreementId: string, request: AdminAgreementUpdateRequest): Promise<AdminAgreement> {
  return http.putData<AdminAgreementUpdateRequest, AdminAgreement>(`/admin/agreements/${agreementId}`, request)
}
