import "./OrderCreate_Footer.scss"
import { useMediaQuery } from 'react-responsive';
import { Breakpoint } from "../../../../../../config/breakpoints"

interface FooterItem {
  classname: string
  title: string
  text: string
  image: string
}

const footerItemData = [
  {
    classname: "eco_friendly",
    title: "Eco-Friendly",
    text: "Peer-to-peer shipping means fewer emissions and no warehouses",
    image: "/images/createOrder/footer/eco_friendly_logo.svg",
  },
  {
    classname: "budget_friendly",
    title: "Budget-Friendly",
    text: "Affordable rates thanks to our efficient and lean operations",
    image: "/images/createOrder/footer/budget_friendly_logo.svg",
  },
  {
    classname: "on_demand",
    title: "On-Demand Convenience",
    text: "Affordable rates thanks to our efficient and lean operations",
    image: "/images/createOrder/footer/on_demand_logo.svg",
  },
  {
    classname: "safety_first",
    title: "Safety First",
    text: "We use Stripe verification and background checks to ensure drivers qualification",
    image: "/images/createOrder/footer/safety_first_logo.svg",
  },
]

export default function OrderCreate_Footer() {

  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop });

  const buildFooterItem = () => {
    return footerItemData.map((item, index) => {
      return (
        <div key={index} className={`order_create__footer__${item.classname}`}>
          <div className={`order_create__footer__${item.classname}__logo`}>
            <div className={`order_create__footer__${item.classname}__logo__span`}>
              <img src={item.image} alt="Logo"/>
            </div>
          </div>
          <div className={`order_create__footer__${item.classname}__title`}>{item.title}</div>
          { isDesktopScreen && <div className={`order_create__footer__${item.classname}__text`}>{item.text}</div>}
        </div>
      )
    })
  }

  return <div className="order_create__footer__info">{buildFooterItem()}</div>
}
