import { http } from "../http"
import { Order, OrderCreate_AddressAndPackagesRequest, OrderUpdate_ConfirmAndAgreementRequest, OrderUpdate_DetailsAndPhotosRequest } from "../../models/order/Order"

export function orderGet(orderId: string): Promise<Order> {
  return http.getData(`/v2/orders/${orderId}`)
}

export function orderCreate_draft(request: OrderCreate_AddressAndPackagesRequest): Promise<Order> {
  return http.postData("/v2/orders/draft", request)
}

export function orderUpdate_CreatePhotos(orderId: string, files?: File[]): Promise<Order> {
  return http.putMultipartDataFiles(`/v2/orders/${orderId}/photos`, files)
}

export function orderUpdate_confirmAndHold(orderId: string, returnUrl: string, request: OrderUpdate_ConfirmAndAgreementRequest): Promise<Order> {
  return http.putData(`/v2/orders/${orderId}/hold?return_url=${returnUrl}`, request)
}

export function orderUpdate_setUser(orderId: string): Promise<Order> {
  return http.putData(`/v2/orders/${orderId}/set-user`)
}

export function orderUpdate(orderId: string, request: Order): Promise<Order> {
  return http.putData(`/v2/orders/${orderId}`, request)
}
