import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { OrderPackage } from "../../models/Package"
import { OrderStatus } from "../../models/order/OrderStatus"
import { Order } from "../../models/order/Order"
import { Address } from "../../models/Address"
import { OrderPhoto } from "../../models/order/OrderFiles"


const initialState: Order = {
  order_id: "",
  status: OrderStatus.FRONTEND_DRAFT,
  packages: [],
  user_rate: 0,

  files: {
    photos_on_creation: [],
  },
}

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setAddressFrom: (state, action: PayloadAction<Address | undefined>) => {
      state.pickup_from = action.payload
    },
    updateAddressFrom: (state, action: PayloadAction<Address>) => {
      if (!state.pickup_from) {
        state.pickup_from = {} as Address
      }
      Object.assign(state.pickup_from, action.payload)
    },
    setAddressTo: (state, action: PayloadAction<Address | undefined>) => {
      state.delivery_to = action.payload
    },
    updateAddressTo: (state, action: PayloadAction<Address>) => {
      if (!state.delivery_to) {
        state.delivery_to = {} as Address
      }
      Object.assign(state.delivery_to, action.payload)
    },


    setPackages: (state, action: PayloadAction<OrderPackage[]>) => {
      state.packages = action.payload
    },
    deletePackage: (state, action: PayloadAction<OrderPackage>) => {
      state.packages = state.packages.filter(item => item.id !== action.payload.id)
    },


    setPickupAtInput: (state, action: PayloadAction<string | undefined>) => {
      state.pickup_at_input = action.payload
    },
    cleanPickupAtInput: (state) => {
      state.pickup_at_input = undefined
    },


    setPickupPhoneNumber: (state, action: PayloadAction<string>) => {
      state.pickup_phone_number = action.payload
    },
    cleanPickupPhoneNumber: (state) => {
      state.pickup_phone_number = undefined
    },



    setDeliveryAtInput: (state, action: PayloadAction<string>) => {
      state.delivery_at_input = action.payload
    },
    cleanDeliveryAtInput: (state) => {
      state.delivery_at_input = undefined
    },

    setDeliveryPhoneNumber: (state, action: PayloadAction<string>) => {
      state.delivery_phone_number = action.payload
    },
    cleanDeliveryPhoneNumber: (state) => {
      state.delivery_phone_number = undefined
    },


    setFilesPhotoOnCreation: (state, action: PayloadAction<OrderPhoto[] | undefined>) => {
      if (state.files) {
        state.files.photos_on_creation = action.payload
      }
    },

    updateOrder: (state, action: PayloadAction<Order>) => {
      Object.assign(state, action.payload)
    },
    clearOrder: (state) => {
      Object.assign(state, initialState)
    }
  }
})

export const { setAddressFrom } = orderSlice.actions
export const { updateAddressFrom } = orderSlice.actions
export const { updateAddressTo } = orderSlice.actions
export const { setAddressTo } = orderSlice.actions

export const { setPackages } = orderSlice.actions
export const { deletePackage } = orderSlice.actions

export const { setPickupAtInput } = orderSlice.actions
export const { cleanPickupAtInput } = orderSlice.actions
export const { setPickupPhoneNumber } = orderSlice.actions
export const { cleanPickupPhoneNumber } = orderSlice.actions

export const { setDeliveryAtInput } = orderSlice.actions
export const { cleanDeliveryAtInput } = orderSlice.actions
export const { setDeliveryPhoneNumber } = orderSlice.actions
export const { cleanDeliveryPhoneNumber } = orderSlice.actions

export const { setFilesPhotoOnCreation } = orderSlice.actions
export const { updateOrder } = orderSlice.actions
export const { clearOrder } = orderSlice.actions

export default orderSlice.reducer
