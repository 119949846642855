import "./OrderCreate_04_Photos.scss"
import { useEffect, useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../../redux/store"
import { setFilesPhotoOnCreation } from "../../../../../../redux/slices/orderSlice"
import { PageStage, PageStep } from "../../PageState"
import { setActiveAddresses, setActivePackages, setActivePhotos, setActivePickupAndDeliveryDetails, setActiveSummaryAndAgreement, setStage, setStep } from "../../../../../../redux/slices/stepSlice"
import { userOrderFileDownload } from "../../../../../../api/order/user_order_api"
import { OrderPhoto } from "../../../../../../models/order/OrderFiles"
import { getBackendUrl } from "../../../../../../config/env"
import { setCreateFiles } from "../../../../../../redux/slices/filesSlice"

export default function OrderCreate_04_Photos() {
  const dispatch = useDispatch<AppDispatch>()
  const currentStep = useSelector((state: RootState) => state.step.currentStep)
  const currentStage = useSelector((state: RootState) => state.step.currentStage)
  const isActivePhotos = useSelector((state: RootState) => state.step.isActivePhotos)
  const rate = useSelector((state: RootState) => state.order.user_rate)
  const photos = useSelector((state: RootState) => state.order.files?.photos_on_creation)
  const storedFiles = useSelector((state: RootState) => state.files.files_on_creation)
  const orderId = useSelector((state: RootState) => state.order.order_id)
  const stepRef = useRef<HTMLDivElement>(null)
  const [files, setFiles] = useState<File[] | undefined>(storedFiles);
 
  const processFile = (file: File) => {
    return URL.createObjectURL(file)
  }

  const [photosSrc, setPhotosSrc] = useState<string[]>(
    () => (files ? files.map(processFile) : [])
  )


  const handleFileUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = Array.from(event.target.files ?? [])
    if (uploadedFiles) {
      setFiles((prevFiles) => { 
        return prevFiles ? [...prevFiles, ...uploadedFiles] : uploadedFiles
      })

      uploadedFiles.forEach((file) => {
        const src = processFile(file);
        setPhotosSrc((prevSrc) => [...prevSrc, src]);
      })

      if (currentStep !== PageStep.Photos || currentStage === PageStage.Edit) {
        dispatch(setCreateFiles(uploadedFiles))
      }
    }
  }

  const getPhoto = async (photo: any) => {
    const photoBlob: Blob | undefined = await userOrderFileDownload(orderId, photo.file_id)
    if (!photoBlob) {
      console.error("Photo URL is undefined")
      return
    }
    return URL.createObjectURL(photoBlob)
  }

  const handleDeleteClick = (index: number) => {
    const updatedFiles = files?.filter((_, i) => i !== index)
    setFiles(updatedFiles && updatedFiles.length > 0 ? updatedFiles as File[] : undefined);

    const updatedPhotosSrc = updatedFiles?.map(processFile) ?? [];
    setPhotosSrc(updatedPhotosSrc);

    if (currentStep !== PageStep.Photos || currentStage === PageStage.Edit) {
      dispatch(setCreateFiles(updatedFiles ?? []))
    }
  }

  const handleProceedButton = () => {
    dispatch(setCreateFiles(files ?? []))

    dispatch(setStep(PageStep.SummaryAndAgreement))
    dispatch(setStage(PageStage.Confirm))

    dispatch(setActiveAddresses(false))
    dispatch(setActivePackages(false))
    dispatch(setActivePickupAndDeliveryDetails(false))
    dispatch(setActivePhotos(false))

    dispatch(setActiveSummaryAndAgreement(true))
  }

  const buildPhotos = () => {
    if (photosSrc && photosSrc.length > 0) {
      return (
        <AnimatePresence>
          {photosSrc.map((src, index) => {
            return (
              <motion.div
                key={index}
                className="user_order_create__photos__photo_wrapper"
                initial={{ opacity: 0, scale: 0.98 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.98 }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                }}
              >
                <motion.img
                  src={src}
                  alt={`Uploaded Preview ${index}`}
                  width="226"
                  height="148"
                  className="user_order_create__photos__photo"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{
                    duration: 1,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                />
                <motion.button
                  className="user_order_create__photos__photo_delete"
                  aria-label="Delete Photo"
                  onClick={() => handleDeleteClick(index)}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{
                    duration: 1,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                />
              </motion.div>
            )
          })}
        </AnimatePresence>
      );
    }
  }

  useEffect(() => {
    if (!files) return
    files.forEach((file) => {
      if (!(file instanceof File)) {
        const fetchPhoto = async () => {
          const src = await getPhoto(file)
          if (src) setPhotosSrc((prevState) => [...prevState, src])
        }
        fetchPhoto();
      }
    })
  })

  useEffect(() => {
    if (currentStep === PageStep.Photos && stepRef.current) {
      const offsetTop = stepRef.current?.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 90,
        behavior: "smooth",
      })
    }
  }, [currentStep]);

  return (isActivePhotos &&
      <div className="user_order_create__photos__section" ref={stepRef}>
        <div className="user_order_create__photos__wrapper">
            <h3 className="user_order_create__photos__title">Add photos of your parcel</h3>
            <div className="user_order_create__photos__photos">
                <div className="user_order_create__photos__preview">
                  {buildPhotos()}
                    <label
                        className="user_order_create__photos__add_label"
                    >
                        Upload a photo
                        <input
                            className="user_order_create__photos__add_input"
                            type="file"
                            multiple
                            onChange={(event) => handleFileUploadChange(event)}
                        />
                    </label>
                </div>
            </div>
        </div>
        <div className="user_order_create__rate_section">
            <div className="user_order_create__rate_wrapper">
                <div className="user_order_create__rate_title">
                    Your Rate:
                </div>
                <div className="user_order_create__rate_amount">{rate} $</div>
            </div>
            <button
                onClick={handleProceedButton}
                className="user_order_create__rate_button"
            >
                {currentStage === PageStage.Fill ? "Set Up Photos" : "Update Your Order"}
            </button>
        </div>
      </div>
  )
}
