import './AgreementsAdminPage.css'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'
import AdminAgreementsTable from '../../../../components/agreement/admin/AdminAgreementsTable/AdminAgreementsTable'

export default function AgreementsAdminPage() {
  return <div className='agreements_admins__page'>
    <AdminTab tabIndex={AdminTabConfig.Agreements.index}/>
    <AdminAgreementsTable/>
  </div>
}
