/* eslint-disable react/jsx-pascal-case */
import "./OrderTimeline.scss"
import { OrderStatus } from "../../../models/order/OrderStatus"
import { UserOrder } from "../../../models/order/UserOrder"

type StepType = {
  id: number
  name: string
  label: string
  hint: string
}

enum TimelineStep {
  placed, // 0
  assigned, // 1
  picked, // 2
  delivered // 3
}

const statusToStepMap: Record<OrderStatus, TimelineStep> = {
  [OrderStatus.FRONTEND_DRAFT]: TimelineStep.placed,
  [OrderStatus.NEW__ADDRESS_AND_PACKAGE_DONE]: TimelineStep.placed,
  [OrderStatus.NEW__DETAILS_DONE]: TimelineStep.placed,
  [OrderStatus.NEW__PHOTOS_DONE]: TimelineStep.placed,

  [OrderStatus.PAYMENT_SETUP_REQUESTED]: TimelineStep.placed,
  [OrderStatus.PAYMENT_SETUP_FAILED]: TimelineStep.placed,
  [OrderStatus.PAYMENT_SETUP_SUCCESS]: TimelineStep.placed,
  [OrderStatus.PAYMENT_HOLD_REQUESTED]: TimelineStep.placed,
  [OrderStatus.PAYMENT_HOLD_FAILED]: TimelineStep.placed,
  [OrderStatus.PAYMENT_HOLD_SUCCESS]: TimelineStep.placed,

  [OrderStatus.ASSIGNED]: TimelineStep.assigned,
  [OrderStatus.PICKED_UP]: TimelineStep.picked,
  [OrderStatus.DELIVERED]: TimelineStep.delivered,
  [OrderStatus.PAYMENT_CAPTURE_REQUESTED]: TimelineStep.delivered,
  [OrderStatus.PAYMENT_CAPTURE_FAILED]: TimelineStep.delivered,
  [OrderStatus.PAYMENT_CAPTURE_SUCCESS]: TimelineStep.delivered,
}

const TimelineSteps = [
  {
    id: 0,
    name: "placed",
    label: "Order Placed",
    hint: "Looking for a driver"
  },
  {
    id: 1,
    name: "assigned",
    label: "Driver Assigned",
    hint: "Your driver is heading towards you"
  },
  {
    id: 2,
    name: "picked",
    label: "Order Picked Up ",
    hint: "Driver is heading towards delivery point"
  },
  {
    id: 3,
    name: "delivered",
    label: "Order Delivered",
    hint: "Order has been successfully delivered"
  },
]

export default function OrderTimeline({ order }: { order: UserOrder }) {
  const currentStep = statusToStepMap[order.status];
  const isStepActive = (stepId: number): boolean => {
    return stepId <= currentStep
  }

  const createTimeLineClass = () => {
    switch (currentStep) {
      case TimelineStep.placed:
        return "order_timeline__timeline order_timeline__timeline-placed"
      case TimelineStep.assigned:
        return "order_timeline__timeline order_timeline__timeline-assigned"
      case TimelineStep.picked:
        return "order_timeline__timeline order_timeline__timeline-picked"
      case TimelineStep.delivered:
        return "order_timeline__timeline order_timeline__timeline-delivered"
    }
  }

  const buildStepAddress = (step: StepType) => {
    switch (step.id) {
      case TimelineStep.picked: 
        return (
          <span className={`order_timeline__step-pickup_address ${currentStep > step.id  ? "order_timeline__step-pickup_address-complete" : ""}`}>
            {order.pickup_from.short_address}
          </span>
        )
      case TimelineStep.delivered:
        return (
          <span className={`order_timeline__step-delivery_address ${step.id === currentStep ? "order_timeline__step-delivery_address-active" : ""}` }>
            {order.delivery_to.short_address}
          </span>
        )
    } 
  }

  const buildStepHint = (step: StepType) => {
    if (step.id === currentStep) {
      return (
        <span className={`order_timeline__hint order_timeline__hint-${step.name}`}>
          {step.hint}
        </span>
      )
    }
  }

  const buildTimelineSteps = () => {
    return TimelineSteps.map((step) => {
      return (
        <li className={
            `order_timeline__step 
            order_timeline__step-${step.name}
            ${isStepActive(step.id) 
              ? "order_timeline__step-active" 
              : ""}`
          }>
          {step.label}
          {buildStepAddress(step)}
          {buildStepHint(step)}
        </li>
      )
    })
  }
 
  return ( 
    <section className="order_timeline__section">
      <ol className={createTimeLineClass()}>
        {buildTimelineSteps()}
      </ol>
    </section>
)}