import useSWR, { SWRResponse } from "swr"
import { PageResponse } from "../../models/Page"
import { useBaseOrders } from "../../utils/swr_utils"
import { OrderStatus } from "../../models/order/OrderStatus"
import { ManagerOrder, ManagerOrderSetUserRequest, ManagerOrderTable } from "../../models/order/ManagerOrder"
import { Order } from "../../models/order/Order"
import { http } from "../http"

export function useManagerOrder(orderId: string): SWRResponse<ManagerOrder> {
  return useSWR<ManagerOrder>([`/manager/orders/${orderId}`])
}

export function useManagerOrders(page?: number, pageSize?: number, orderStatus?: OrderStatus): SWRResponse<PageResponse<ManagerOrderTable>> {
  return useBaseOrders("/manager/orders/search", page, pageSize, orderStatus)
}

export function managerOrderSetUser(orderId: string, request: ManagerOrderSetUserRequest): Promise<Order> {
  return http.putData(`/manager/orders/${orderId}/set-user`, request)
}
