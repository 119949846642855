import { Route, Routes, useLocation } from "react-router-dom"
import {
  ABOUT_US__PATH,
  ACCOUNT_ONBOARDING__PATH,
  ADMIN__AGREEMENT_CARD__PATH,
  ADMIN__AGREEMENT_CREATE__PATH,
  ADMIN__AGREEMENTS__PATH,
  ADMIN__DASHBOARD__PATH,
  ADMIN__ORDER_CARD__PATH,
  ADMIN__ORDERS__PATH,
  ADMIN__RATE_CARD__PATH,
  ADMIN__RATES__PATH,
  ADMIN__RATES_CREATE__PATH,
  ADMIN__ROUTE_CARD__PATH,
  ADMIN__ROUTES__PATH,
  ADMIN__USER_CARD__PATH,
  ADMIN__USERS__PATH,
  ADMIN__VISITS__PATH,
  BECOME_A_DRIVER,
  DRIVER__AGREEMENT_CARD__PATH,
  DRIVER__AGREEMENTS__PATH,
  DRIVER__DASHBOARD__PATH,
  DRIVER__ORDER_CARD__PATH,
  DRIVER__ORDERS__PATH,
  DRIVER__ROUTE_CARD__PATH,
  DRIVER__ROUTE_CREATE__PATH,
  DRIVER__ROUTES__PATH,
  DRIVER__STRIPE__PATH,
  HELP__PATH,
  LOGIN__PATH,
  LOGIN_REDIRECT__PATH,
  LOGIN_WITH_OTP__PATH,
  LOGOUT__PATH,
  MAIN__PATH,
  MANAGER__AGREEMENT_CARD__PATH,
  MANAGER__AGREEMENTS__PATH,
  MANAGER__DASHBOARD__PATH,
  MANAGER__ORDER_CARD__PATH,
  MANAGER__ORDERS__PATH,
  MANAGER__STRIPE__PATH,
  ORDER_TRACK_CARD__PATH,
  ORDER_TRACKS__PATH,
  PAGE_403__PATH,
  PROFILE__PATH,
  USER__AGREEMENT_CARD__PATH,
  USER__AGREEMENTS__PATH,
  USER__ORDER_CARD__PATH,
  USER__ORDER_CREATE_CONTINUE__PATH,
  USER__ORDER_CREATE__PATH,
  USER__ORDERS__PATH
} from "./config/page_paths"
import AboutUsPage from "./pages/header/AboutUs/AboutUsPage"
import NotFoundPage from "./pages/error/NotFound/NotFoundPage"
import DriverRouteCreatePage from "./pages/route/driver/DriverRouteCreatePage/DriverRouteCreatePage"
import DriverRouteCardPage from "./pages/route/driver/DriverRouteCardPage/DriverRouteCardPage"
import DriverRoutesPage from "./pages/route/driver/DriverRoutesPage/DriverRoutesPage"
import AdminRoutesPage from "./pages/route/admin/AdminRoutesPage/AdminRoutesPage"
import AdminRouteCardPage from "./pages/route/admin/AdminRouteCardPage/AdminRouteCardPage"
import AdminVisitsPage from "./pages/visit/admin/AdminVisitsPage/AdminVisitsPage"
import AdminRateCardPage from "./pages/rate/admin/AdminRateCardPage/AdminRateCardPage"
import AdminRatesPage from "./pages/rate/admin/AdminRatesPage/AdminRatesPage"
import AdminRateCreatePage from "./pages/rate/admin/AdminRateCreatePage/AdminRateCreatePage"
import UserProfilePage from "./pages/user/user/UserProfilePage/UserProfilePage"
import AdminUsersPage from "./pages/user/admin/AdminUsersPage/AdminUsersPage"
import AdminUserCardPage from "./pages/user/admin/AdminUserCardPage/AdminUserCardPage"
import UserOrderCardPage from "./pages/order/users/UserOrderCardPage/UserOrderCardPage"
import OrderTrackCardPage from "./pages/order/unauthorized/OrderTrackCardPage/OrderTrackCardPage"
import AdminOrdersPage from "./pages/order/admins/OrdersAdminsPage/AdminOrdersPage"
import AdminOrderCardPage from "./pages/order/admins/OrderAdminsCardPage/AdminOrderCardPage"
import MainPage from "./pages/header/Main/MainPage"
import DriverDashboardPage from "./pages/dashboard/driver/DriverDashboardPage/DriverDashboardPage"
import DriverOrderCardPage from "./pages/order/drivers/DriverOrderCardPage/DriverOrderCardPage"
import DriverOrdersPage from "./pages/order/drivers/DriverOrdersPage/DriverOrdersPage"
import UserOrdersPage from "./pages/order/users/UserOrdersPage/UserOrdersPage"
import AdminDashboardPage from "./pages/dashboard/admin/AdminDashboardPage/AdminDashboardPage"
import Layout from "./components/layout/Layout/Layout"
import LoginPage from "./pages/auth/Login/LoginPage"
import AuthOtpPage from "./pages/auth/./AuthOtp/AuthOtpPage"
import AuthRedirectPage from "./pages/auth/AuthRedirectPage"
import LogoutPage from "./pages/auth/LogoutPage"
import HelpPage from "./pages/header/Help/HelpPage"
import OrderTrackSearchPage from "./pages/order/unauthorized/OrderTrackSearchPage/OrderTrackSearchPage"
import UnauthorizedPage from "./pages/error/Unauthorized/UnauthorizedPage"
import UserAccountOnboardingPage from "./pages/user/user/UserAccountOnboardingPage/UserAccountOnboardingPage"
import BecomeADriverPage from "./pages/header/BecomeADriver/BecomeADriverPage"
import DriverStripePage from "./pages/user/driver/DriverStripePage/DriverStripePage"
import AgreementAdminsCardPage from "./pages/agreement/admins/AgreementAdminsCardPage/AgreementAdminsCardPage"
import AgreementsAdminPage from "./pages/agreement/admins/AgreementsAdminsPage/AgreementsAdminPage"
import DriverAgreementsPage from "./pages/agreement/driver/DriverAgreementsPage/DriverAgreementsPage"
import DriverAgreementCardPage from "./pages/agreement/driver/DriverAgreementCardPage/DriverAgreementCardPage"
import AgreementAdminsCreatePage from "./pages/agreement/admins/AgreementAdminsCreatePage/AgreementAdminsCreatePage"
import UserAgreementCardPage from "./pages/agreement/user/UserAgreementCardPage/UserAgreementCardPage"
import UserAgreementsPage from "./pages/agreement/user/UserAgreementsPage/UserAgreementsPage"
import { useLayoutEffect } from "react"
import UserOrderCreatePage from "./pages/order/users/UserOrderCreatePage/UserOrderCreatePage"
// FIXME import { Helmet } from "react-helmet"
import ManagerOrdersPage from "./pages/order/managers/ManagerOrdersPage/ManagerOrdersPage"
import ManagerDashboardPage from "./pages/dashboard/manager/ManagerDashboardPage/ManagerDashboardPage"
import ManagerAgreementCardPage from "./pages/agreement/manager/ManagerAgreementCardPage/ManagerAgreementCardPage"
import ManagerAgreementsPage from "./pages/agreement/manager/ManagerAgreementsPage/ManagerAgreementsPage"
import ManagerStripePage from "./pages/user/manager/ManagerStripePage/ManagerStripePage"
import ManagerOrderCardPage from "./pages/order/managers/ManagerOrderCardPage/ManagerOrderCardPage"

import './styles/global.scss'

export default function App() {
  const location = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  

  return <>
    <Layout>
      <Routes>
        {/* DASHBOARDS */}
        <Route path={DRIVER__DASHBOARD__PATH} element={<DriverDashboardPage/>}/>
        <Route path={ADMIN__DASHBOARD__PATH} element={<AdminDashboardPage/>}/>
        <Route path={MANAGER__DASHBOARD__PATH} element={<ManagerDashboardPage/>}/>


        {/* AGREEMENTS */}
        <Route path={USER__AGREEMENT_CARD__PATH} element={<UserAgreementCardPage/>}/>
        <Route path={USER__AGREEMENTS__PATH} element={<UserAgreementsPage/>}/>

        <Route path={DRIVER__AGREEMENT_CARD__PATH} element={<DriverAgreementCardPage/>}/>
        <Route path={DRIVER__AGREEMENTS__PATH} element={<DriverAgreementsPage/>}/>

        <Route path={ADMIN__AGREEMENT_CREATE__PATH} element={<AgreementAdminsCreatePage/>}/>
        <Route path={ADMIN__AGREEMENT_CARD__PATH} element={<AgreementAdminsCardPage/>}/>
        <Route path={ADMIN__AGREEMENTS__PATH} element={<AgreementsAdminPage/>}/>

        <Route path={MANAGER__AGREEMENT_CARD__PATH} element={<ManagerAgreementCardPage/>}/>
        <Route path={MANAGER__AGREEMENTS__PATH} element={<ManagerAgreementsPage/>}/>


        {/*ORDERS */}
        <Route path={ORDER_TRACK_CARD__PATH} element={<OrderTrackCardPage/>}/>
        <Route path={ORDER_TRACKS__PATH} element={<OrderTrackSearchPage/>}/>

        <Route path={USER__ORDER_CREATE__PATH} element={<UserOrderCreatePage/>}/>
        <Route path={USER__ORDER_CREATE_CONTINUE__PATH} element={<UserOrderCreatePage/>}/>
        <Route path={USER__ORDER_CARD__PATH} element={<UserOrderCardPage/>}/>
        <Route path={USER__ORDERS__PATH} element={<UserOrdersPage/>}/>

        <Route path={DRIVER__ORDER_CARD__PATH} element={<DriverOrderCardPage/>}/>
        <Route path={DRIVER__ORDERS__PATH} element={<DriverOrdersPage/>}/>

        <Route path={ADMIN__ORDER_CARD__PATH} element={<AdminOrderCardPage/>}/>
        <Route path={ADMIN__ORDERS__PATH} element={<AdminOrdersPage/>}/>

        <Route path={MANAGER__ORDERS__PATH} element={<ManagerOrdersPage/>}/>
        <Route path={MANAGER__ORDER_CARD__PATH} element={<ManagerOrderCardPage/>}/>


        {/* ROUTES */}
        <Route path={DRIVER__ROUTE_CREATE__PATH} element={<DriverRouteCreatePage/>}/>
        <Route path={DRIVER__ROUTE_CARD__PATH} element={<DriverRouteCardPage/>}/>
        <Route path={DRIVER__ROUTES__PATH} element={<DriverRoutesPage/>}/>

        <Route path={ADMIN__ROUTE_CARD__PATH} element={<AdminRouteCardPage/>}/>
        <Route path={ADMIN__ROUTES__PATH} element={<AdminRoutesPage/>}/>


        {/* RATES */}
        <Route path={ADMIN__RATES_CREATE__PATH} element={<AdminRateCreatePage/>}/>
        <Route path={ADMIN__RATE_CARD__PATH} element={<AdminRateCardPage/>}/>
        <Route path={ADMIN__RATES__PATH} element={<AdminRatesPage/>}/>


        {/* USERS */}
        <Route path={PROFILE__PATH} element={<UserProfilePage/>}/>
        <Route path={BECOME_A_DRIVER} element={<BecomeADriverPage/>}/>
        <Route path={ADMIN__USER_CARD__PATH} element={<AdminUserCardPage/>}/>
        <Route path={ADMIN__USERS__PATH} element={<AdminUsersPage/>}/>
        <Route path={DRIVER__STRIPE__PATH} element={<DriverStripePage/>}/>
        <Route path={MANAGER__STRIPE__PATH} element={<ManagerStripePage/>}/>


        {/*VISITS*/}
        <Route path={ADMIN__VISITS__PATH} element={<AdminVisitsPage/>}/>


        {/*HELP*/}
        <Route path={HELP__PATH} element={<HelpPage/>}/>


        {/* AUTH */}
        <Route path={ACCOUNT_ONBOARDING__PATH} element={<UserAccountOnboardingPage/>}/>
        <Route path={LOGIN__PATH} element={<LoginPage/>}/>
        <Route path={LOGIN_WITH_OTP__PATH} element={<AuthOtpPage/>}/>
        <Route path={LOGIN_REDIRECT__PATH} element={<AuthRedirectPage/>}/>
        <Route path={LOGOUT__PATH} element={<LogoutPage/>}/>


        {/* STATIC PAGES */}
        <Route path={ABOUT_US__PATH} element={<AboutUsPage/>}/>
        <Route path={PAGE_403__PATH} element={<UnauthorizedPage/>}/>

        <Route path={MAIN__PATH} element={<MainPage/>}/>

        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </Layout>
    <div>
      {/*FIXME*/}
      {/*<Helmet>*/}
      {/*  {getSeoKeywords() && <meta name="keywords" content={getSeoKeywords()}/>}*/}
      {/*</Helmet>*/}
    </div>
  </>
}
