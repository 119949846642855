import { OrderStatus } from '../models/order/OrderStatus'

const FRONTEND_DRAFT = 'Frontend Draft'
const NEW_ADDRESS_AND_PACKAGE_STATUS_DONE = 'Draft'
const NEW__DETAILS_DONE = 'Details done'
const NEW__PHOTOS_DONE = 'Photos done'
const PAYMENT_SETUP_REQUESTED_STATUS = 'Payment setup'
const PAYMENT_SETUP_FAILED_STATUS = 'Payment setup failure'
const PAYMENT_SETUP_SUCCESS_STATUS = 'Payment was setup'
const PAYMENT_HOLD_REQUESTED_STATUS = 'Payment hold requested'
const PAYMENT_HOLD_FAILED_STATUS = 'Payment hold was failed'
const PAYMENT_HOLD_SUCCESS_STATUS = 'Payment was held'
const ASSIGNED_STATUS = 'Driver assigned'
const PICKED_UP_STATUS = 'Driver on a route'
const DELIVERED_STATUS = 'Delivered'
const PAYMENT_CAPTURE_REQUESTED_STATUS = 'Payment capture requested'
const PAYMENT_CAPTURE_FAILED_STATUS = 'Payment capture failure'
const PAYMENT_CAPTURE_SUCCESS_STATUS = 'Payment captured'

export function toUiOrderStatus(orderStatus: OrderStatus): string {
  switch (orderStatus) {
    case OrderStatus.FRONTEND_DRAFT:
      return FRONTEND_DRAFT
    case OrderStatus.NEW__ADDRESS_AND_PACKAGE_DONE:
      return NEW_ADDRESS_AND_PACKAGE_STATUS_DONE
    case OrderStatus.NEW__DETAILS_DONE:
      return NEW__DETAILS_DONE
    case OrderStatus.NEW__PHOTOS_DONE:
      return NEW__PHOTOS_DONE
    case OrderStatus.PAYMENT_SETUP_REQUESTED:
      return PAYMENT_SETUP_REQUESTED_STATUS
    case OrderStatus.PAYMENT_SETUP_FAILED:
      return PAYMENT_SETUP_FAILED_STATUS
    case OrderStatus.PAYMENT_SETUP_SUCCESS:
      return PAYMENT_SETUP_SUCCESS_STATUS
    case OrderStatus.PAYMENT_HOLD_REQUESTED:
      return PAYMENT_HOLD_REQUESTED_STATUS
    case OrderStatus.PAYMENT_HOLD_FAILED:
      return PAYMENT_HOLD_FAILED_STATUS
    case OrderStatus.PAYMENT_HOLD_SUCCESS:
      return PAYMENT_HOLD_SUCCESS_STATUS
    case OrderStatus.ASSIGNED:
      return ASSIGNED_STATUS
    case OrderStatus.PICKED_UP:
      return PICKED_UP_STATUS
    case OrderStatus.DELIVERED:
      return DELIVERED_STATUS
    case OrderStatus.PAYMENT_CAPTURE_REQUESTED:
      return PAYMENT_CAPTURE_REQUESTED_STATUS
    case OrderStatus.PAYMENT_CAPTURE_FAILED:
      return PAYMENT_CAPTURE_FAILED_STATUS
    case OrderStatus.PAYMENT_CAPTURE_SUCCESS:
      return PAYMENT_CAPTURE_SUCCESS_STATUS
  }
}

export function fromUserOrderStatus(userOrderStatus?: string): OrderStatus | undefined {
  switch (userOrderStatus) {
    case '':
      return undefined
    case FRONTEND_DRAFT:
      return OrderStatus.FRONTEND_DRAFT
    case NEW_ADDRESS_AND_PACKAGE_STATUS_DONE:
      return OrderStatus.NEW__ADDRESS_AND_PACKAGE_DONE
    case NEW__DETAILS_DONE:
      return OrderStatus.NEW__DETAILS_DONE
    case NEW__PHOTOS_DONE:
      return OrderStatus.NEW__PHOTOS_DONE
    case PAYMENT_SETUP_REQUESTED_STATUS:
      return OrderStatus.PAYMENT_SETUP_REQUESTED
    case PAYMENT_SETUP_FAILED_STATUS:
      return OrderStatus.PAYMENT_SETUP_FAILED
    case PAYMENT_SETUP_SUCCESS_STATUS:
      return OrderStatus.PAYMENT_SETUP_SUCCESS
    case PAYMENT_HOLD_REQUESTED_STATUS:
      return OrderStatus.PAYMENT_HOLD_REQUESTED
    case PAYMENT_HOLD_FAILED_STATUS:
      return OrderStatus.PAYMENT_HOLD_FAILED
    case PAYMENT_HOLD_SUCCESS_STATUS:
      return OrderStatus.PAYMENT_HOLD_SUCCESS
    case ASSIGNED_STATUS:
      return OrderStatus.ASSIGNED
    case PICKED_UP_STATUS:
      return OrderStatus.PICKED_UP
    case DELIVERED_STATUS:
      return OrderStatus.DELIVERED
    case PAYMENT_CAPTURE_REQUESTED_STATUS:
      return OrderStatus.PAYMENT_CAPTURE_REQUESTED
    case PAYMENT_CAPTURE_FAILED_STATUS:
      return OrderStatus.PAYMENT_CAPTURE_FAILED
    case PAYMENT_CAPTURE_SUCCESS_STATUS:
      return OrderStatus.PAYMENT_CAPTURE_SUCCESS
  }
}
