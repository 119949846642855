import "./LoginPage.scss"

import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { getBackendUrl } from "../../../config/env"
import { AnimatePresence, motion } from "framer-motion"
import { useMediaQuery } from 'react-responsive'
import { Breakpoint } from "../../../config/breakpoints"
import { useNavigate } from "react-router-dom"
import { MAIN__PATH } from "../../../config/page_paths"


const OTP_LENGTH = 6

const TITLES = [
  "Ready to explore?",
  "Join Us Today"
]

const IMAGES = {
  desktop: [
    "/images/login/shipping/pictures/shipping_picture_1.jpg",
    "/images/login/shipping/pictures/shipping_picture_2.jpg"
  ],
  mobile: [
    "/images/login/shipping/illustrations/shipping_illustration_1.png",
    "/images/login/shipping/illustrations/shipping_illustration_2.png",
    "/images/login/shipping/illustrations/shipping_illustration_3.png",
    "/images/login/shipping/illustrations/shipping_illustration_4.png",
    "/images/login/shipping/illustrations/shipping_illustration_5.png",
    "/images/login/shipping/illustrations/shipping_illustration_6.png",
    "/images/login/shipping/illustrations/shipping_illustration_7.png",
    "/images/login/shipping/illustrations/shipping_illustration_8.png",
    "/images/login/shipping/illustrations/shipping_illustration_9.png",
    "/images/login/shipping/illustrations/shipping_illustration_10.png",
    "/images/login/shipping/illustrations/shipping_illustration_11.png",
  ]
}

export default function OrderCreate_06_Login() {

  const userId = useSelector((state: RootState) => state.user.user_id)
  const [isOtpOpen, setOtpOpen] = useState(false)
  const [otp, setOtp] = useState(Array(OTP_LENGTH).fill(""))
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const isDesktopScreen = useMediaQuery({ query: Breakpoint.desktop });

  const handleSendOtpClick = () => {
    setOtpOpen(true)
  }

  const handleBackClick = () => {
    setOtpOpen(false)
  }

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (newOtp.join("").length === OTP_LENGTH) {
      handleOtpOnComplete(newOtp.join(""))
    }

    if (value && index < (OTP_LENGTH - 1)) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  const handleOtpOnComplete = (completeOtp: string) => {
    console.log("otp: " + completeOtp)
  }

  const handleOtpPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pastedData = event.clipboardData.getData("text").trim()
    const newOtp = [...otp]

    pastedData.split("").forEach((char, index) => {
      if (index < newOtp.length) {
        newOtp[index] = char
      }
    })

    setOtp(newOtp)
  }

  const handleSignInClick = () => {
  }

  const buildOtpCells = () => {
    return otp.map((value, index) => {
      return <input
        key={index}
        ref={(el) => (inputRefs.current[index] = el)}
        type="text"
        value={value}
        onChange={(event) => handleOtpChange(event, index)}
        onKeyDown={(event) => handleKeydown(event, index)}
        onPaste={(event) => handleOtpPaste(event)}
        maxLength={1}
        className="login_page__login__otp_input_cell"
      />
    })
  }

  const loginTitle = TITLES[Math.floor(Math.random() * TITLES.length)]
  const buildImageSrc = () => {
    if (isDesktopScreen) {
      return IMAGES.desktop[Math.floor(Math.random() * IMAGES.desktop.length)]
    } else {
      return IMAGES.mobile[Math.floor(Math.random() * IMAGES.mobile.length)]
    }
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (userId) {
      navigate(MAIN__PATH)
    }
  }, [userId])

  return (!userId &&
      <AnimatePresence mode="wait">
          <div className="login_page__section">
              <div className="login_page__wrapper-login">
                  <h2 className="login_page__title">{loginTitle}</h2>
                {isDesktopScreen && <h3 className="login_page__subtitle">Let’s begin!</h3>}
                  <div className="login_page__login">
                      <motion.div
                          key="login"
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.4 }}
                          className={`login_page__container ${isOtpOpen ? "slide-left" : "slide-right"}`}
                      >
                          <div className="login_page__login__wrapper">
                              <div className="login_page__login__google">
                                  <button className="login_page__login__button_google"
                                          onClick={() => window.location.href = `${getBackendUrl()}/login?redirect_to=${window.location.href}`}
                                  >
                                      Sign In With Google
                                  </button>
                              </div>
                              <p className="login_page__login__or">
                                  or
                              </p>
                              <div className="login_page__login__otp">
                                  <h5 className="login_page__login__title-otp">
                                      Enter email to receive a One-Time Password
                                  </h5>
                                  <label className="login_page__login__label">
                                      <input
                                          className="login_page__login__input"
                                          type="email"
                                          name="email"
                                          placeholder="Enter your email"
                                      />
                                  </label>
                                  <button
                                      className="login_page__login__button_otp"
                                      onClick={handleSendOtpClick}
                                  >
                                      Request One-Time Password
                                  </button>
                              </div>
                          </div>
                          <div className="login_page__login__otp_wrapper">
                              <button
                                  className="login_page__login__otp_button_back"
                                  onClick={handleBackClick}
                              >
                                  Back
                              </button>
                              <h4 className="login_page__login__otp_title">Enter One-Time Password to Sign In</h4>
                              <div className="login_page__login__otp_input">
                                {buildOtpCells()}
                              </div>
                              <button
                                  onClick={handleSignInClick}
                                  className="login_page__login__otp_button_signin"
                              >
                                  Sign In
                              </button>
                              <button className="login_page__login__otp_button_resend">Resend Code</button>
                          </div>
                      </motion.div>
                  </div>
              </div>
              <div className="login_page__wrapper-image">
                  <motion.img
                      key={buildImageSrc()}
                      className="login_page__image"
                      src={buildImageSrc()}
                      alt="Join iUjex"
                      width="779px"
                      height="660px"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.8, ease: "easeInOut" }}
                  />
              </div>
          </div>
      </AnimatePresence>
  )
}
